// reducers/authSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { showError, showSuccess } from '../../helpers/toast';
import axios from 'axios';

const API = axios.create({

  baseURL: process.env.REACT_APP_BACKEND_URL // Replace this with your API's base URL

  // Other default configurations (headers, timeout, etc.) can be set here if needed
});

API.interceptors.response.use(
  (response) => {
      return Promise.resolve(response);
  },
  (error) => {
      showError(error.response?.data?.error ?? error.message);
      return Promise.reject(error);
  }
);

const initialState = {
  user: null,
  loading: false,
  error: null,
};

export const registerUser = createAsyncThunk('/auth/register', async (userData) => {
  try {
    const response = await API.post('/auth/register', userData);
    showSuccess('Registration Successfully!');
    return response.data;
  } catch (error) {
    throw new Error('Failed to register user');
  }
});

export const loginUser = createAsyncThunk('/auth/login', async (credentials) => {
  try {
    const response = await API.post('/auth/login', credentials);
    if(response.data.user.is_admin){
      showSuccess('Login Successfully!');
      return response.data;
    }else{
      showError('Login Failed!');
    }
    
  } catch (error) {
    throw new Error('Failed to log in');
  }
});


export const logoutUser = createAsyncThunk('/auth/logout', async () => {
  try {
    await API.delete('/auth/logout');
    
  } catch (error) {
    throw new Error('Failed to log out');
  }
});

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(registerUser.pending, (state) => {
        state.loading = true;
      })
      .addCase(registerUser.fulfilled, (state, action) => {
        state.loading = false;
        state.user = action.payload;
        state.error = null;
      })
      .addCase(registerUser.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(loginUser.pending, (state) => {
        state.loading = true;
      })
      .addCase(loginUser.fulfilled, (state, action) => {
        state.loading = false;
        state.user = action.payload;
        state.error = null;
      })
      .addCase(loginUser.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(logoutUser.pending, (state) => {
        state.loading = true;
      })
      .addCase(logoutUser.fulfilled, (state) => {
        state.loading = false;
        state.user = null;
        state.error = null;
      })
      .addCase(logoutUser.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export default authSlice.reducer;
