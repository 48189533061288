import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getUser, updateUser } from '../../../store/reducers/userSlice';
import Navbar from '../../navbar';
import Header from '../../header';
import userImagePlaceholder from "../../../assets/media/avatar2.jpg";

const Profile = () => {
    const dispatch = useDispatch();
    const user = useSelector((state) => state.auth.user);
    const getUserData = useSelector((state) => state.usersdata.getuserdata);
    const [fullname, setFullname] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [password, setPassword] = useState('');
    const [nationalidnumber, setNationalidnumber] = useState('');
    const [selectedImage, setSelectedImage] = useState(null);
    const [imagedata,setImagedata] = useState('')
    useEffect(() => {
        dispatch(getUser({ userId: user.user.id, userData: { accessToken: user.access_token } }));
    }, [dispatch, user,imagedata]);

    if (!getUserData) {
        return <div>Loading...</div>;
    }

    const handleImageUpload = (e) => {
        const imageFile = e.target.files[0];
        const reader = new FileReader();
        reader.onloadend = () => {
            setSelectedImage(reader.result);
            setImagedata(reader.result)
        };
        reader.readAsDataURL(imageFile);
    };

    const removeImage = () => {
        setSelectedImage(null);
    };
    const postdata = () => {
        const userId = user.user.id;
        const userData = {
            email: email || getUserData.email,
            phone: phone || getUserData.phone,
            full_name: fullname || getUserData.full_name,
            national_id_number: nationalidnumber || getUserData.national_id_number,
            ...(password && { password }),
            image: selectedImage || getUserData.image,
            accessToken: user.access_token,
        };
        dispatch(updateUser({ userId, userData }));
        dispatch(getUser({ userId: user.user.id, userData: { accessToken: user.access_token } }));
    };

    return (
        <div>
            <Navbar />
            <Header />
            <div className='wrapper'>
                <div className='content-wrapper'>
                    <div className='profHeader'>
                        <div className='profHeadLeft'>
                            <h2>My Profile</h2>
                        </div>
                       <div className='profHeadRight'>
                            <div className='btngroup'>
                                <Link className='buttonSecondary' to={'/'}>Delete Account</Link>
                                <Link className='buttonPrimary' onClick={postdata}>Save Changes</Link>
                            </div>
                        </div>
                    </div>

                    <div className='divUpload'>
                        <div className='uploadLeft'>
                            <img className='avatar' src={imagedata||getUserData.image||userImagePlaceholder} alt="" />
                            <p>Upload your profile picture. <strong>Max size 2MB</strong></p>
                        </div>
                        <div className='uploadRight'>
                            <div className='btngroup'>
                                <button className='buttonSecondary' onClick={removeImage}>Remove</button>
                                <input
                                    type="file"
                                    id="imageUpload"
                                    accept="image/*"
                                    style={{ display: 'none' }}
                                    onChange={handleImageUpload}
                                />
                                <label htmlFor="imageUpload" className='buttonPrimary'>Browse</label>
                            </div>
                        </div>
                    </div>

                        <div className="accordion accordion-flush profileAccord" id="accordionProfile">
                        <div className="accordion-item">
                            <h2 className="accordion-header">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#personalnformation">Personal Information</button>
                            </h2>
                            <div id="personalnformation" className="accordion-collapse collapse" data-bs-parent="#accordionProfile">
                                <div className="accordion-body">
                                    <div className='empAdd'>
                                        <div className='row'>
                                            <div className='col-12 col-lg-4'>
                                                <div className='fieldWrap'>
                                                    <label>Full Name</label>
                                                    <input onChange={(e)=>setFullname(e.target.value)}  value={fullname||getUserData.full_name} type='text' placeholder='Marvin McKinney' />
                                                </div>
                                            </div>
                                            <div className='col-12 col-lg-4'>
                                                <div className='fieldWrap'>
                                                    <label>National Identity Number</label>
                                                    <input onChange={(e)=>setNationalidnumber(e.target.value)} value={nationalidnumber||getUserData.national_id_number} type='text' placeholder='Marvin McKinney' />
                                                </div>
                                            </div>
                                            <div className='col-12 col-lg-4'>
                                                <div className='fieldWrap'>
                                                    <label>Email Address</label>
                                                    <input onChange={(e)=>setEmail(e.target.value)} value={email||getUserData.email} type='text' placeholder='Marvin McKinney' />
                                                </div>
                                            </div>
                                            <div className='col-12 col-lg-4'>
                                                <div className='fieldWrap'>
                                                    <label>Phone No.</label>
                                                    <input onChange={(e)=>setPhone(e.target.value)} value={phone ||getUserData.phone}  type='text' placeholder='(702) 555-0122' />
                                                </div>
                                            </div>
                                            {/* <div className='col-12 col-lg-4'>
                                                <div className='fieldWrap noLabel'>
                                                    <button onClick={postdata} >Update</button>
                                                </div>
                                            </div> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                       
                        <div className="accordion-item">
                            <h2 className="accordion-header">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#changePassword">Change Password</button>
                            </h2>
                            <div id="changePassword" className="accordion-collapse collapse" data-bs-parent="#accordionProfile">
                                <div className="accordion-body">
                                    <div className='empAdd'>
                                        <div className='row'>
                                            <div className='col-12 col-lg-4'>
                                                <div className='fieldWrap'>
                                                    <label>Password</label>
                                                    <input onChange={(e)=>setPassword(e.target.value)} type='text' placeholder='Enter your new password' />
                                                </div>
                                            </div>
                                          
                                            {/* <div className='col-12 col-lg-4'>
                                                <div className='fieldWrap noLabel'>
                                                    <button onClick={postdata}>Update</button>
                                                </div>
                                            </div> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='row profileNotify'>
                        <div className='col-12 col-lg-6'>
                            Get All Notifications On
                        </div>
                        <div className='col-12 col-lg-6'></div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Profile;
