import React, { useState, Fragment } from 'react'
import { AiOutlineEyeInvisible, AiOutlineEye } from 'react-icons/ai'
const GBInput = ({ label, type, placeholder, value, setValue, id, name, iconSrc, readOnly = null, error = null }) => {
    const [inputType, setInputType] = useState(type);
    const toggleInputType = () => {
        setInputType((prevType) => (prevType === 'password' ? 'text' : 'password'));
    };

    const style = {
        position: 'absolute',
        bottom: '10px',
        right: '12px',
        width: '20px',
        height: '20px',
    }
    return (
        <div className='gb-field-wrap'>
            <label>{label}</label>
            <input className='form-class'
                type={inputType}
                placeholder={placeholder}
                value={value}
                onChange={setValue}
                autoComplete={"false"}
                id={id}
                name={name}
                readOnly={readOnly}
            />
            {
                <>
                    {type === 'password' && <Fragment>
                        {
                            inputType === 'password' ? <AiOutlineEyeInvisible onClick={toggleInputType} style={style} /> : <AiOutlineEye style={style} onClick={toggleInputType} />
                        }
                    </Fragment>}
                    <>
                        {iconSrc && inputType !== 'password' && <img src={iconSrc} className="iconField" alt="" />}
                    </>
                </>
            }
            {/* {error ? <div>{error}</div> : null} */}
        </div>
    )
}

export default GBInput