import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Navbar from '../../navbar';
import Header from '../../header';
import { useDispatch, useSelector } from 'react-redux';
import { fetchCertificates } from '../../../store/reducers/certificateSlice';
import imageCertificate from "../../../assets/media/image-certificate.png";

const AppCertifications = () => {
    const [searchQuery, setSearchQuery] = useState('');
    const dispatch = useDispatch();
    const user = useSelector((state) => state.auth.user);
    const authToken = user.access_token;

    const certificates = useSelector((state) => state.certificates);

    useEffect(() => {
        dispatch(fetchCertificates({ accessToken: authToken }));
    }, [authToken, dispatch]);

    const formatDate = (inputDate) => {
        const date = new Date(inputDate);
        const options = { year: "numeric", month: "long", day: "2-digit" };
        return date.toLocaleDateString(undefined, options);
    };

    function hasDatePassed(dateString) {
        const inputDate = new Date(dateString);
        const currentDate = new Date();
        return inputDate < currentDate;
    }

    const categorizeCertificatesWithExpiry = () => {
        let expiredCertificates = [], validCertificates = [];
        certificates?.data?.forEach((item) => {
            if (hasDatePassed(item.expired_at)) {
                expiredCertificates.push(item)
            }
            else {
                validCertificates.push(item)
            }
        });
        return {
            expiredCertificates,
            validCertificates
        };
    };

    const handleSearchInputChange = (event) => {
        setSearchQuery(event.target.value);
    };

    const filteredCertificates = certificates.data?.filter((certificate) =>{
    return certificate.issued_to === user.user.email  &&  Object.values(certificate).some((value) =>
    value && value.toString().toLowerCase().includes(searchQuery.toLowerCase())
)
}).sort((a, b) => b.id - a.id) || [];


    return (
        <div>
            <Navbar />
            <Header handleSearchInputChange={handleSearchInputChange}/>
            <div className='wrapper'>
                <div className='boxAppBoard boxWhite'>
                    <div className='boardHeader noMg'>
                        <div className='boardHeadLeft'>
                            <h2>Certifications</h2>
                        </div>
                        <div className='boardHeadRight'>
                            <div className='boardFilter'>
                                <div className="form-check form-check-inline">
                                    <input className="form-check-input" type="checkbox" value="All" checked />
                                    <label className="form-check-label">All</label>
                                </div>
                                <div className="form-check form-check-inline">
                                    <input className="form-check-input" type="checkbox" value="Near To Expire" />
                                    <label className="form-check-label">Near To Expire</label>
                                </div>
                                <div className="form-check form-check-inline">
                                    <input className="form-check-input" type="checkbox" value="Expired" />
                                    <label className="form-check-label">Expired</label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='divFiles'>
                    <div className='rowCertifications'>
                        {filteredCertificates.length ? (
                            filteredCertificates.map((item, index) => (
                                <div className='colCertification' key={index}>
                                    <img src={imageCertificate} alt="" />
                                    <div className='contCertificate'>
                                        <h5 title={item.name}>{item?.name.substring(0, 12) + "..."}</h5>
                                        <div className='issue'>Issued: <span>{formatDate(item?.issued_at)}</span></div>
                                        <div className='issue'>Expire: <span className='due'>{formatDate(item?.expired_at)}</span></div>
                                    </div>
                                </div>
                            ))
                        ) : (
                            <p>No Certificate Found</p>
                        )}
                    </div>
                </div>
                <div className='divFiles'>
                    <div className='headFiles'>
                        <div className='leftFiles'>
                            <h3 className='noMg'>Expired</h3>
                        </div>
                        <div className='rightFiles'></div>
                    </div>
                    <div className='rowCertifications'>
                        {categorizeCertificatesWithExpiry()?.expiredCertificates.length ? (
                            categorizeCertificatesWithExpiry()?.expiredCertificates.map((item, index) => (
                                <div className='colCertification' key={index}>
                                    <img src={imageCertificate} alt="" />
                                    <div className='contCertificate'>
                                        <h5>{item?.name}</h5>
                                        <div className='issue'>Issued: <span>{formatDate(item?.issued_at)}</span></div>
                                        <div className='expire'>Expire: <span className='due'>{formatDate(item?.expired_at)}</span></div>
                                    </div>
                                </div>
                            ))
                        ) : (
                            <p>No Certificate Found</p>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AppCertifications;
