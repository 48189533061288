// import React from 'react'
// import iconFilePdf from "../../../assets/media/icon-file-pdf.svg";
// import iconFileXls from "../../../assets/media/icon-file-xls.svg";
// import iconFileImage from "../../../assets/media/icon-file-image.svg";
// import iconFileDoc from "../../../assets/media/icon-file-doc.svg";
// import iconFileDownload from "../../../assets/media/icon-file-download.svg";
// import iconSearch from "../../../assets/media/icon-search.svg";
// import iconCaretLeft from "../../../assets/media/icon-caret-left.svg";
// import iconCaretRight from "../../../assets/media/icon-caret-right.svg";
// import iconCaretDown from "../../../assets/media/icon-caret-down.svg";

// import { Link } from 'react-router-dom';
// import Navbar from '../../navbar';
// import Header from '../../header';

// const ApprovedApp = () => {
//     return (
//         <div>
//             <Navbar />
//             <Header />
//             <div className='wrapper'>
//                 <div className='boxAppBoard boxWhite'>
//                     <div className='boardHeader'>
//                         <div className='boardHeadLeft'>
//                             <h2>Approved Applications</h2>
//                         </div>
//                         <div className='boardHeadRight'>
//                             <div className='searchForm'>
//                                 <input type='text' placeholder='input search text' />
//                                 <button><img src={iconSearch} alt='' /></button>  
//                             </div>
//                             <div className='datePicker'>
//                             </div>
//                         </div>
//                     </div>
//                     <div className='boardContent'>
//                         <div className='boardThead'>
//                             <div className='boardCol w-85'>
//                                 S.NO.
//                             </div>
//                             <div className='boardCol w-165'>
//                                 Application No.
//                             </div>
//                             <div className='boardCol w-adj4'>
//                                 Application Name
//                             </div>
//                             <div className='boardCol w-165'>
//                                 Submitted Date
//                             </div>
//                             <div className='boardCol w-165'>
//                                 Registered Date
//                             </div>
//                             <div className='boardCol w-165'>
//                                 Attachments
//                             </div>
//                             <div className='boardCol w-110'>

//                             </div>
//                         </div>
//                         <div className='boardTrow'>
//                             <div className='boardCol w-85'>
//                                 01
//                             </div>
//                             <div className='boardCol w-165'>
//                                 GB-4936209
//                             </div>
//                             <div className='boardCol w-adj4'>
//                                 <div className='boardTitle'>
//                                     <strong>Monthly Gaming Tax Return</strong>
//                                     <span>Gaming House Operator</span>
//                                 </div>
//                             </div>
//                             <div className='boardCol w-165'>
//                                 Nov 28, 2022
//                             </div>
//                             <div className='boardCol w-165'>
//                                 Nov 28, 2022
//                             </div>
//                             <div className='boardCol w-165'>
//                                 <div className='boardDownload'>
//                                     <Link to={'/'}><img src={iconFilePdf} alt='' /></Link>
//                                     <Link to={'/'}><img src={iconFileDoc} alt='' /></Link>
//                                     <Link to={'/'}><img src={iconFileImage} alt='' /></Link>
//                                 </div>
//                             </div>
//                             <div className='boardCol w-110'>
//                                 <div className='boardActions'>
//                                     <Link to={'/'}><img src={iconFileDownload} alt='' /></Link>
//                                 </div>
//                             </div>
//                         </div>
//                         <div className='boardTrow'>
//                             <div className='boardCol w-85'>
//                                 02
//                             </div>
//                             <div className='boardCol w-165'>
//                                 GB-4939907
//                             </div>
//                             <div className='boardCol w-adj4'>
//                                 <div className='boardTitle'>
//                                     <strong>Gaming Licence Limited Renewal</strong>
//                                     <span>GBB 1-A-3</span>
//                                 </div>
//                             </div>
//                             <div className='boardCol w-165'>
//                                 May 20, 2022
//                             </div>
//                             <div className='boardCol w-165'>
//                                 May 20, 2022
//                             </div>
//                             <div className='boardCol w-165'>
//                                 <div className='boardDownload'>
//                                     <Link to={'/'}><img src={iconFilePdf} alt='' /></Link>
//                                     <Link to={'/'}><img src={iconFileXls} alt='' /></Link>
//                                 </div>
//                             </div>
//                             <div className='boardCol w-110'>
//                                 <div className='boardActions'>
//                                     <Link to={'/'}><img src={iconFileDownload} alt='' /></Link>
//                                 </div>
//                             </div>
//                         </div>
//                         <div className='boardTrow'>
//                             <div className='boardCol w-85'>
//                                 03
//                             </div>
//                             <div className='boardCol w-165'>
//                                 GB-4931109
//                             </div>
//                             <div className='boardCol w-adj4'>
//                                 <div className='boardTitle'>
//                                     <strong>Monthly Gaming Tax Return</strong>
//                                     <span>Casino</span>
//                                 </div>
//                             </div>
//                             <div className='boardCol w-165'>
//                                 Nov 16, 2022
//                             </div>
//                             <div className='boardCol w-165'>
//                                 Nov 16, 2022
//                             </div>
//                             <div className='boardCol w-165'>
//                                 <div className='boardDownload'>
//                                     <Link to={'/'}><img src={iconFileDoc} alt='' /></Link>
//                                     <Link to={'/'}><img src={iconFileImage} alt='' /></Link>
//                                     <Link to={'/'}><img src={iconFileXls} alt='' /></Link>
//                                 </div>
//                             </div>
//                             <div className='boardCol w-110'>
//                                 <div className='boardActions'>
//                                     <Link to={'/'}><img src={iconFileDownload} alt='' /></Link>
//                                 </div>
//                             </div>
//                         </div>
//                         <div className='boardTrow'>
//                             <div className='boardCol w-85'>
//                                 04
//                             </div>
//                             <div className='boardCol w-165'>
//                                 GB-4930098
//                             </div>
//                             <div className='boardCol w-adj4'>
//                                 <div className='boardTitle'>
//                                     <strong>Proxy Gaming Licence</strong>
//                                     <span>GBB 1-B-1</span>
//                                 </div>
//                             </div>
//                             <div className='boardCol w-165'>
//                                 May 9, 2022
//                             </div>
//                             <div className='boardCol w-165'>
//                                 May 9, 2022
//                             </div>
//                             <div className='boardCol w-165'>
//                                 <div className='boardDownload'>
//                                     <Link to={'/'}><img src={iconFilePdf} alt='' /></Link>
//                                     <Link to={'/'}><img src={iconFileXls} alt='' /></Link>
//                                 </div>
//                             </div>
//                             <div className='boardCol w-110'>
//                                 <div className='boardActions'>
//                                     <Link to={'/'}><img src={iconFileDownload} alt='' /></Link>
//                                 </div>
//                             </div>
//                         </div>
//                         <div className='boardTrow'>
//                             <div className='boardCol w-85'>
//                                 05
//                             </div>
//                             <div className='boardCol w-165'>
//                                 GB-4934653
//                             </div>
//                             <div className='boardCol w-adj4'>
//                                 <div className='boardTitle'>
//                                     <strong>Mobile Gaming Licence</strong>
//                                     <span>GBB 1-B-1</span>
//                                 </div>
//                             </div>
//                             <div className='boardCol w-165'>
//                                 Dec 2, 2022
//                             </div>
//                             <div className='boardCol w-165'>
//                                 Dec 2, 2022
//                             </div>
//                             <div className='boardCol w-165'>
//                                 <div className='boardDownload'>
//                                     <Link to={'/'}><img src={iconFilePdf} alt='' /></Link>
//                                     <Link to={'/'}><img src={iconFileDoc} alt='' /></Link>
//                                     <Link to={'/'}><img src={iconFileImage} alt='' /></Link>
//                                 </div>
//                             </div>
//                             <div className='boardCol w-110'>
//                                 <div className='boardActions'>
//                                     <Link to={'/'}><img src={iconFileDownload} alt='' /></Link>
//                                 </div>
//                             </div>
//                         </div>
//                         <div className='boardTrow'>
//                             <div className='boardCol w-85'>
//                                 06
//                             </div>
//                             <div className='boardCol w-165'>
//                                 GB-4930987
//                             </div>
//                             <div className='boardCol w-adj4'>
//                                 <div className='boardTitle'>
//                                     <strong>Restricted Interactive Gaming Licence</strong>
//                                     <span>GBB 1-B-1</span>
//                                 </div>
//                             </div>
//                             <div className='boardCol w-165'>
//                                 Oct 30, 2022
//                             </div>
//                             <div className='boardCol w-165'>
//                                 Oct 30, 2022
//                             </div>
//                             <div className='boardCol w-165'>
//                                 <div className='boardDownload'>
//                                     <Link to={'/'}><img src={iconFilePdf} alt='' /></Link>
//                                     <Link to={'/'}><img src={iconFileDoc} alt='' /></Link>
//                                     <Link to={'/'}><img src={iconFileXls} alt='' /></Link>
//                                 </div>
//                             </div>
//                             <div className='boardCol w-110'>
//                                 <div className='boardActions'>
//                                     <Link to={'/'}><img src={iconFileDownload} alt='' /></Link>
//                                 </div>
//                             </div>
//                         </div>
//                         <div className='boardTrow'>
//                             <div className='boardCol w-85'>
//                                 07
//                             </div>
//                             <div className='boardCol w-165'>
//                                 GB-4936209
//                             </div>
//                             <div className='boardCol w-adj4'>
//                                 <div className='boardTitle'>
//                                     <strong>Monthly Gaming Tax Return</strong>
//                                     <span>Gaming House Operator</span>
//                                 </div>
//                             </div>
//                             <div className='boardCol w-165'>
//                                 Nov 28, 2022
//                             </div>
//                             <div className='boardCol w-165'>
//                                 Nov 28, 2022
//                             </div>
//                             <div className='boardCol w-165'>
//                                 <div className='boardDownload'>
//                                     <Link to={'/'}><img src={iconFilePdf} alt='' /></Link>
//                                     <Link to={'/'}><img src={iconFileDoc} alt='' /></Link>
//                                     <Link to={'/'}><img src={iconFileImage} alt='' /></Link>
//                                 </div>
//                             </div>
//                             <div className='boardCol w-110'>
//                                 <div className='boardActions'>
//                                     <Link to={'/'}><img src={iconFileDownload} alt='' /></Link>
//                                 </div>
//                             </div>
//                         </div>
//                         <div className='boardTrow'>
//                             <div className='boardCol w-85'>
//                                 08
//                             </div>
//                             <div className='boardCol w-165'>
//                                 GB-4939907
//                             </div>
//                             <div className='boardCol w-adj4'>
//                                 <div className='boardTitle'>
//                                     <strong>Gaming Licence Limited Renewal</strong>
//                                     <span>GBB 1-A-3</span>
//                                 </div>
//                             </div>
//                             <div className='boardCol w-165'>
//                                 May 20, 2022
//                             </div>
//                             <div className='boardCol w-165'>
//                                 May 20, 2022
//                             </div>
//                             <div className='boardCol w-165'>
//                                 <div className='boardDownload'>
//                                     <Link to={'/'}><img src={iconFilePdf} alt='' /></Link>
//                                     <Link to={'/'}><img src={iconFileXls} alt='' /></Link>
//                                 </div>
//                             </div>
//                             <div className='boardCol w-110'>
//                                 <div className='boardActions'>
//                                     <Link to={'/'}><img src={iconFileDownload} alt='' /></Link>
//                                 </div>
//                             </div>
//                         </div>
//                         <div className='boardTrow'>
//                             <div className='boardCol w-85'>
//                                 09
//                             </div>
//                             <div className='boardCol w-165'>
//                                 GB-4931109
//                             </div>
//                             <div className='boardCol w-adj4'>
//                                 <div className='boardTitle'>
//                                     <strong>Monthly Gaming Tax Return</strong>
//                                     <span>Casino</span>
//                                 </div>
//                             </div>
//                             <div className='boardCol w-165'>
//                                 Nov 16, 2022
//                             </div>
//                             <div className='boardCol w-165'>
//                                 Nov 16, 2022
//                             </div>
//                             <div className='boardCol w-165'>
//                                 <div className='boardDownload'>
//                                     <Link to={'/'}><img src={iconFileDoc} alt='' /></Link>
//                                     <Link to={'/'}><img src={iconFileImage} alt='' /></Link>
//                                     <Link to={'/'}><img src={iconFileXls} alt='' /></Link>
//                                 </div>
//                             </div>
//                             <div className='boardCol w-110'>
//                                 <div className='boardActions'>
//                                     <Link to={'/'}><img src={iconFileDownload} alt='' /></Link>
//                                 </div>
//                             </div>
//                         </div>
//                         <div className='boardTrow'>
//                             <div className='boardCol w-85'>
//                                 10
//                             </div>
//                             <div className='boardCol w-165'>
//                                 GB-4930098
//                             </div>
//                             <div className='boardCol w-adj4'>
//                                 <div className='boardTitle'>
//                                     <strong>Proxy Gaming Licence</strong>
//                                     <span>GBB 1-B-1</span>
//                                 </div>
//                             </div>
//                             <div className='boardCol w-165'>
//                                 May 9, 2022
//                             </div>
//                             <div className='boardCol w-165'>
//                                 May 9, 2022
//                             </div>
//                             <div className='boardCol w-165'>
//                                 <div className='boardDownload'>
//                                     <Link to={'/'}><img src={iconFilePdf} alt='' /></Link>
//                                     <Link to={'/'}><img src={iconFileXls} alt='' /></Link>
//                                 </div>
//                             </div>
//                             <div className='boardCol w-110'>
//                                 <div className='boardActions'>
//                                     <Link to={'/'}><img src={iconFileDownload} alt='' /></Link>
//                                 </div>
//                             </div>
//                         </div>
//                         <div className='boardTrow'>
//                             <div className='boardCol w-85'>
//                                 11
//                             </div>
//                             <div className='boardCol w-165'>
//                                 GB-4934653
//                             </div>
//                             <div className='boardCol w-adj4'>
//                                 <div className='boardTitle'>
//                                     <strong>Mobile Gaming Licence</strong>
//                                     <span>GBB 1-B-1</span>
//                                 </div>
//                             </div>
//                             <div className='boardCol w-165'>
//                                 Dec 2, 2022
//                             </div>
//                             <div className='boardCol w-165'>
//                                 Dec 2, 2022
//                             </div>
//                             <div className='boardCol w-165'>
//                                 <div className='boardDownload'>
//                                     <Link to={'/'}><img src={iconFilePdf} alt='' /></Link>
//                                     <Link to={'/'}><img src={iconFileDoc} alt='' /></Link>
//                                     <Link to={'/'}><img src={iconFileImage} alt='' /></Link>
//                                 </div>
//                             </div>
//                             <div className='boardCol w-110'>
//                                 <div className='boardActions'>
//                                     <Link to={'/'}><img src={iconFileDownload} alt='' /></Link>
//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//                     <div className='boardFooter'>
//                         <div className='pagination'>
//                             <Link className='disabled' to={'/'}><img src={iconCaretLeft} alt='' /></Link>
//                             <Link to={'/'}>1</Link>
//                             <Link to={'/'}>4</Link>
//                             <Link to={'/'}>5</Link>
//                             <Link className='active' to={'/'}>6</Link>
//                             <Link to={'/'}>7</Link>
//                             <Link to={'/'}>8</Link>
//                             <Link to={'/'}>50</Link>
//                             <Link to={'/'}><img src={iconCaretRight} alt='' /></Link>
//                             <Link className='wAuto' to={'/'}>10 / page <img className='ml-6' src={iconCaretDown} alt='' /></Link>
//                             <span>Goto</span>
//                             <input type='text' />
//                         </div>
//                     </div>
//                 </div>
//             </div>
//         </div>
//     )
// }
// export default ApprovedApp

// import React from 'react'
// import iconFilePdf from "../../../assets/media/icon-file-pdf.svg";
// import iconFileXls from "../../../assets/media/icon-file-xls.svg";
// import iconFileImage from "../../../assets/media/icon-file-image.svg";
// import iconFileDoc from "../../../assets/media/icon-file-doc.svg";
// import iconFileDelete from "../../../assets/media/icon-file-delete.svg";
// import iconFileDownload from "../../../assets/media/icon-file-download.svg";
// import iconSearch from "../../../assets/media/icon-search.svg";
// import iconCaretLeft from "../../../assets/media/icon-caret-left.svg";
// import iconCaretRight from "../../../assets/media/icon-caret-right.svg";
// import iconCaretDown from "../../../assets/media/icon-caret-down.svg";

// import { Link } from 'react-router-dom';
// import Navbar from '../../navbar';
// import Header from '../../header';

// const PendingApp = () => {
//     return (
//         <div>
//             <Navbar />
//             <Header />
//             <div className='wrapper'>
//                 <div className='boxAppBoard boxWhite'>
//                     <div className='boardHeader'>
//                         <div className='boardHeadLeft'>
//                             <h2>Pending Applications</h2>
//                         </div>
//                         <div className='boardHeadRight'>
//                             <div className='searchForm'>
//                                 <input type='text' placeholder='input search text' />
//                                 <button><img src={iconSearch} alt='' /></button>  
//                             </div>
//                             <div className='datePicker'>
//                             </div>
//                         </div>
//                     </div>
//                     <div className='boardContent'>
//                         <div className='boardThead'>
//                             <div className='boardCol w-85'>
//                                 S.NO.
//                             </div>
//                             <div className='boardCol w-165'>
//                                 Application No.
//                             </div>
//                             <div className='boardCol w-adj3'>
//                                 Application Name
//                             </div>
//                             <div className='boardCol w-165'>
//                                 Submitted Date
//                             </div>
//                             <div className='boardCol w-165'>
//                                 Attachments
//                             </div>
//                             <div className='boardCol w-110'>

//                             </div>
//                         </div>
//                         <div className='boardTrow'>
//                             <div className='boardCol w-85'>
//                                 01
//                             </div>
//                             <div className='boardCol w-165'>
//                                 GB-4936209
//                             </div>
//                             <div className='boardCol w-adj3'>
//                                 <div className='boardTitle'>
//                                     <strong>Monthly Gaming Tax Return</strong>
//                                     <span>Gaming House Operator</span>
//                                 </div>
//                             </div>
//                             <div className='boardCol w-165'>
//                                 Nov 28, 2022
//                             </div>
//                             <div className='boardCol w-165'>
//                                 <div className='boardDownload'>
//                                     <Link to={'/'}><img src={iconFilePdf} alt='' /></Link>
//                                     <Link to={'/'}><img src={iconFileDoc} alt='' /></Link>
//                                     <Link to={'/'}><img src={iconFileImage} alt='' /></Link>
//                                 </div>
//                             </div>
//                             <div className='boardCol w-110'>
//                                 <div className='boardActions'>
//                                     <Link to={'/'}><img src={iconFileDelete} alt='' /></Link>
//                                     <Link to={'/'}><img src={iconFileDownload} alt='' /></Link>
//                                 </div>
//                             </div>
//                         </div>
//                         <div className='boardTrow'>
//                             <div className='boardCol w-85'>
//                                 02
//                             </div>
//                             <div className='boardCol w-165'>
//                                 GB-4939907
//                             </div>
//                             <div className='boardCol w-adj3'>
//                                 <div className='boardTitle'>
//                                     <strong>Gaming Licence Limited Renewal</strong>
//                                     <span>GBB 1-A-3</span>
//                                 </div>
//                             </div>
//                             <div className='boardCol w-165'>
//                                 May 20, 2022
//                             </div>
//                             <div className='boardCol w-165'>
//                                 <div className='boardDownload'>
//                                     <Link to={'/'}><img src={iconFilePdf} alt='' /></Link>
//                                     <Link to={'/'}><img src={iconFileXls} alt='' /></Link>
//                                 </div>
//                             </div>
//                             <div className='boardCol w-110'>
//                                 <div className='boardActions'>
//                                     <Link to={'/'}><img src={iconFileDelete} alt='' /></Link>
//                                     <Link to={'/'}><img src={iconFileDownload} alt='' /></Link>
//                                 </div>
//                             </div>
//                         </div>
//                         <div className='boardTrow'>
//                             <div className='boardCol w-85'>
//                                 03
//                             </div>
//                             <div className='boardCol w-165'>
//                                 GB-4931109
//                             </div>
//                             <div className='boardCol w-adj3'>
//                                 <div className='boardTitle'>
//                                     <strong>Monthly Gaming Tax Return</strong>
//                                     <span>Casino</span>
//                                 </div>
//                             </div>
//                             <div className='boardCol w-165'>
//                                 Nov 16, 2022
//                             </div>
//                             <div className='boardCol w-165'>
//                                 <div className='boardDownload'>
//                                     <Link to={'/'}><img src={iconFileDoc} alt='' /></Link>
//                                     <Link to={'/'}><img src={iconFileImage} alt='' /></Link>
//                                     <Link to={'/'}><img src={iconFileXls} alt='' /></Link>
//                                 </div>
//                             </div>
//                             <div className='boardCol w-110'>
//                                 <div className='boardActions'>
//                                     <Link to={'/'}><img src={iconFileDelete} alt='' /></Link>
//                                     <Link to={'/'}><img src={iconFileDownload} alt='' /></Link>
//                                 </div>
//                             </div>
//                         </div>
//                         <div className='boardTrow'>
//                             <div className='boardCol w-85'>
//                                 04
//                             </div>
//                             <div className='boardCol w-165'>
//                                 GB-4930098
//                             </div>
//                             <div className='boardCol w-adj3'>
//                                 <div className='boardTitle'>
//                                     <strong>Proxy Gaming Licence</strong>
//                                     <span>GBB 1-B-1</span>
//                                 </div>
//                             </div>
//                             <div className='boardCol w-165'>
//                                 May 9, 2022
//                             </div>
//                             <div className='boardCol w-165'>
//                                 <div className='boardDownload'>
//                                     <Link to={'/'}><img src={iconFilePdf} alt='' /></Link>
//                                     <Link to={'/'}><img src={iconFileXls} alt='' /></Link>
//                                 </div>
//                             </div>
//                             <div className='boardCol w-110'>
//                                 <div className='boardActions'>
//                                     <Link to={'/'}><img src={iconFileDelete} alt='' /></Link>
//                                     <Link to={'/'}><img src={iconFileDownload} alt='' /></Link>
//                                 </div>
//                             </div>
//                         </div>
//                         <div className='boardTrow'>
//                             <div className='boardCol w-85'>
//                                 05
//                             </div>
//                             <div className='boardCol w-165'>
//                                 GB-4934653
//                             </div>
//                             <div className='boardCol w-adj3'>
//                                 <div className='boardTitle'>
//                                     <strong>Mobile Gaming Licence</strong>
//                                     <span>GBB 1-B-1</span>
//                                 </div>
//                             </div>
//                             <div className='boardCol w-165'>
//                                 Dec 2, 2022
//                             </div>
//                             <div className='boardCol w-165'>
//                                 <div className='boardDownload'>
//                                     <Link to={'/'}><img src={iconFilePdf} alt='' /></Link>
//                                     <Link to={'/'}><img src={iconFileDoc} alt='' /></Link>
//                                     <Link to={'/'}><img src={iconFileImage} alt='' /></Link>
//                                 </div>
//                             </div>
//                             <div className='boardCol w-110'>
//                                 <div className='boardActions'>
//                                     <Link to={'/'}><img src={iconFileDelete} alt='' /></Link>
//                                     <Link to={'/'}><img src={iconFileDownload} alt='' /></Link>
//                                 </div>
//                             </div>
//                         </div>
//                         <div className='boardTrow'>
//                             <div className='boardCol w-85'>
//                                 06
//                             </div>
//                             <div className='boardCol w-165'>
//                                 GB-4930987
//                             </div>
//                             <div className='boardCol w-adj3'>
//                                 <div className='boardTitle'>
//                                     <strong>Restricted Interactive Gaming Licence</strong>
//                                     <span>GBB 1-B-1</span>
//                                 </div>
//                             </div>
//                             <div className='boardCol w-165'>
//                                 Oct 30, 2022
//                             </div>
//                             <div className='boardCol w-165'>
//                                 <div className='boardDownload'>
//                                     <Link to={'/'}><img src={iconFilePdf} alt='' /></Link>
//                                     <Link to={'/'}><img src={iconFileDoc} alt='' /></Link>
//                                     <Link to={'/'}><img src={iconFileXls} alt='' /></Link>
//                                 </div>
//                             </div>
//                             <div className='boardCol w-110'>
//                                 <div className='boardActions'>
//                                     <Link to={'/'}><img src={iconFileDelete} alt='' /></Link>
//                                     <Link to={'/'}><img src={iconFileDownload} alt='' /></Link>
//                                 </div>
//                             </div>
//                         </div>
//                         <div className='boardTrow'>
//                             <div className='boardCol w-85'>
//                                 07
//                             </div>
//                             <div className='boardCol w-165'>
//                                 GB-4936209
//                             </div>
//                             <div className='boardCol w-adj3'>
//                                 <div className='boardTitle'>
//                                     <strong>Monthly Gaming Tax Return</strong>
//                                     <span>Gaming House Operator</span>
//                                 </div>
//                             </div>
//                             <div className='boardCol w-165'>
//                                 Nov 28, 2022
//                             </div>
//                             <div className='boardCol w-165'>
//                                 <div className='boardDownload'>
//                                     <Link to={'/'}><img src={iconFilePdf} alt='' /></Link>
//                                     <Link to={'/'}><img src={iconFileDoc} alt='' /></Link>
//                                     <Link to={'/'}><img src={iconFileImage} alt='' /></Link>
//                                 </div>
//                             </div>
//                             <div className='boardCol w-110'>
//                                 <div className='boardActions'>
//                                     <Link to={'/'}><img src={iconFileDelete} alt='' /></Link>
//                                     <Link to={'/'}><img src={iconFileDownload} alt='' /></Link>
//                                 </div>
//                             </div>
//                         </div>
//                         <div className='boardTrow'>
//                             <div className='boardCol w-85'>
//                                 08
//                             </div>
//                             <div className='boardCol w-165'>
//                                 GB-4939907
//                             </div>
//                             <div className='boardCol w-adj3'>
//                                 <div className='boardTitle'>
//                                     <strong>Gaming Licence Limited Renewal</strong>
//                                     <span>GBB 1-A-3</span>
//                                 </div>
//                             </div>
//                             <div className='boardCol w-165'>
//                                 May 20, 2022
//                             </div>
//                             <div className='boardCol w-165'>
//                                 <div className='boardDownload'>
//                                     <Link to={'/'}><img src={iconFilePdf} alt='' /></Link>
//                                     <Link to={'/'}><img src={iconFileXls} alt='' /></Link>
//                                 </div>
//                             </div>
//                             <div className='boardCol w-110'>
//                                 <div className='boardActions'>
//                                     <Link to={'/'}><img src={iconFileDelete} alt='' /></Link>
//                                     <Link to={'/'}><img src={iconFileDownload} alt='' /></Link>
//                                 </div>
//                             </div>
//                         </div>
//                         <div className='boardTrow'>
//                             <div className='boardCol w-85'>
//                                 09
//                             </div>
//                             <div className='boardCol w-165'>
//                                 GB-4931109
//                             </div>
//                             <div className='boardCol w-adj3'>
//                                 <div className='boardTitle'>
//                                     <strong>Monthly Gaming Tax Return</strong>
//                                     <span>Casino</span>
//                                 </div>
//                             </div>
//                             <div className='boardCol w-165'>
//                                 Nov 16, 2022
//                             </div>
//                             <div className='boardCol w-165'>
//                                 <div className='boardDownload'>
//                                     <Link to={'/'}><img src={iconFileDoc} alt='' /></Link>
//                                     <Link to={'/'}><img src={iconFileImage} alt='' /></Link>
//                                     <Link to={'/'}><img src={iconFileXls} alt='' /></Link>
//                                 </div>
//                             </div>
//                             <div className='boardCol w-110'>
//                                 <div className='boardActions'>
//                                     <Link to={'/'}><img src={iconFileDelete} alt='' /></Link>
//                                     <Link to={'/'}><img src={iconFileDownload} alt='' /></Link>
//                                 </div>
//                             </div>
//                         </div>
//                         <div className='boardTrow'>
//                             <div className='boardCol w-85'>
//                                 10
//                             </div>
//                             <div className='boardCol w-165'>
//                                 GB-4930098
//                             </div>
//                             <div className='boardCol w-adj3'>
//                                 <div className='boardTitle'>
//                                     <strong>Proxy Gaming Licence</strong>
//                                     <span>GBB 1-B-1</span>
//                                 </div>
//                             </div>
//                             <div className='boardCol w-165'>
//                                 May 9, 2022
//                             </div>
//                             <div className='boardCol w-165'>
//                                 <div className='boardDownload'>
//                                     <Link to={'/'}><img src={iconFilePdf} alt='' /></Link>
//                                     <Link to={'/'}><img src={iconFileXls} alt='' /></Link>
//                                 </div>
//                             </div>
//                             <div className='boardCol w-110'>
//                                 <div className='boardActions'>
//                                     <Link to={'/'}><img src={iconFileDelete} alt='' /></Link>
//                                     <Link to={'/'}><img src={iconFileDownload} alt='' /></Link>
//                                 </div>
//                             </div>
//                         </div>
//                         <div className='boardTrow'>
//                             <div className='boardCol w-85'>
//                                 11
//                             </div>
//                             <div className='boardCol w-165'>
//                                 GB-4934653
//                             </div>
//                             <div className='boardCol w-adj3'>
//                                 <div className='boardTitle'>
//                                     <strong>Mobile Gaming Licence</strong>
//                                     <span>GBB 1-B-1</span>
//                                 </div>
//                             </div>
//                             <div className='boardCol w-165'>
//                                 Dec 2, 2022
//                             </div>
//                             <div className='boardCol w-165'>
//                                 <div className='boardDownload'>
//                                     <Link to={'/'}><img src={iconFilePdf} alt='' /></Link>
//                                     <Link to={'/'}><img src={iconFileDoc} alt='' /></Link>
//                                     <Link to={'/'}><img src={iconFileImage} alt='' /></Link>
//                                 </div>
//                             </div>
//                             <div className='boardCol w-110'>
//                                 <div className='boardActions'>
//                                     <Link to={'/'}><img src={iconFileDelete} alt='' /></Link>
//                                     <Link to={'/'}><img src={iconFileDownload} alt='' /></Link>
//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//                     <div className='boardFooter'>
//                         <div className='pagination'>
//                             <Link className='disabled' to={'/'}><img src={iconCaretLeft} alt='' /></Link>
//                             <Link to={'/'}>1</Link>
//                             <Link to={'/'}>4</Link>
//                             <Link to={'/'}>5</Link>
//                             <Link className='active' to={'/'}>6</Link>
//                             <Link to={'/'}>7</Link>
//                             <Link to={'/'}>8</Link>
//                             <Link to={'/'}>50</Link>
//                             <Link to={'/'}><img src={iconCaretRight} alt='' /></Link>
//                             <Link className='wAuto' to={'/'}>10 / page <img className='ml-6' src={iconCaretDown} alt='' /></Link>
//                             <span>Goto</span>
//                             <input type='text' />
//                         </div>
//                     </div>
//                 </div>
//             </div>
//         </div>
//     )
// }
// export default PendingApp

import React, { useEffect, useState } from 'react';
import iconFilePdf from "../../../assets/media/icon-file-pdf.svg";
import iconFileXls from "../../../assets/media/icon-file-xls.svg";
import iconFileImage from "../../../assets/media/icon-file-image.svg";
import iconFileDoc from "../../../assets/media/icon-file-doc.svg";
import iconFileDownload from "../../../assets/media/icon-file-download.svg";
import iconSearch from "../../../assets/media/icon-search.svg";
import iconCaretLeft from "../../../assets/media/icon-caret-left.svg";
import iconCaretRight from "../../../assets/media/icon-caret-right.svg";
import iconCaretDown from "../../../assets/media/icon-caret-down.svg";
import iconMenuDots from '../../../assets/media/icon-menu-dots.svg';

import { Link } from 'react-router-dom';
import Navbar from '../../navbar';
import Header from '../../header';
import { useDispatch, useSelector } from 'react-redux';
import { formatDate } from '../dashboard/aapDashboard';
import { fetchApplications, updateApplication } from '../../../store/reducers/applicationSlice';
import Pagination from '../dashboard/appPagination';
import Modal from 'react-modal';
import { fetchCertificates, postCertificates } from '../../../store/reducers/certificateSlice';
import { createNotification } from '../../../store/reducers/notificationSlice';
import iconFilecertification from "../../../assets/media/icon-certification.svg";
import ReactModal from 'react-modal';
import { showError } from '../../../helpers/toast';

const ApprovedApp = () => {
    const dispatch = useDispatch();
    const user = useSelector((state) => state.auth.user);
    const authToken = user.access_token;
    const applications = useSelector((state) => state.applications);
    const [searchQuery, setSearchQuery] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const getallUserdata = useSelector((state) => state.userdata.getalluserdata);
    const [filteredApplication, setFilteredApplication] = useState([]);
    const [modalIsOpen, setIsOpen] = React.useState(false);
    const [selectedDuaration, setselectedDuaration] = useState('')
    const categories = useSelector((state) => state.categories.data);
    const [selectedItem, setSelectedItem] = useState([])
    const certificates = useSelector((state) => state.certificates);

    useEffect(() => {
        dispatch(fetchApplications({ accessToken: authToken }));
        dispatch(fetchCertificates({ accessToken: authToken }));
    }, [authToken, dispatch]);

    useEffect(() => {
        if (applications.data.length > 0 && getallUserdata.length > 0) {
            const mergedData = applications.data.map((appItem) => {
                const userItem = getallUserdata.find((userData) => userData.id === appItem.user_id);
                if (userItem) {
                    return {
                        ...appItem,
                        applicationId: appItem.id,
                        ...userItem,
                        userId: userItem.id,
                    };
                }
                return { ...appItem, applicationId: appItem.id };
            });

            const filteredData = mergedData.filter((item) =>
                item.company_id === user.user.company_id &&
                item.state === "approved" &&
                Object.values(item).some((value) =>
                    value && value.toString().toLowerCase().includes(searchQuery.toLowerCase())
                )
            );

            setFilteredApplication(filteredData);
            console.log(filteredData, 'Filtered Data');
        }
    }, [searchQuery, applications.data, getallUserdata, user.user.company_id]);

    const handleSearchInputChange = (e) => {
        setSearchQuery(e.target.value);
    };

    const getTotalPages = () => {
        if (Number.isInteger(filteredApplication.length / 10)) return filteredApplication.length / 10;
        return Math.floor(filteredApplication.length / 10) + 1;
    };

    const handlecertification = (e) => {

        if (e === 'approved') {
            setIsOpen(true);
        }
    }

    const closebutton = () => {
        setIsOpen(false)
    }


    const confirmcertification = (e) => {
        const filteredCategories = categories.filter((item) => item.name === selectedItem.form);
        const filteredCertificate = certificates.data.filter((item) =>item.application_id === selectedItem.applicationId);
        console.log(filteredCategories,categories,selectedItem.form,'kkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkk');
        console.log(certificates.data.filter((item) =>item.application_id === selectedItem.applicationId) ,'nnnnnnnnnnnnnnnnnnnnnnnnnn')
        if (filteredCertificate.length != 0) {
            return showError('Certificate Already Exists');
        }
        const currentDate = new Date();
        const expirationDate = new Date(currentDate);

        if (selectedDuaration === '6month') {
            expirationDate.setMonth(expirationDate.getMonth() + 6);
        } else if (selectedDuaration === '1year') {
            expirationDate.setFullYear(expirationDate.getFullYear() + 1);
        }

        const postdata = {
            name: selectedItem.name,
            application_id:selectedItem.applicationId,
            issued_at: currentDate.toISOString().split('T')[0],
            category_id: filteredCategories[0].id,
            issued_to: selectedItem.user_id,
            issued_by: user.user.id,
            expired_at: expirationDate.toISOString().split('T')[0],
        }
        const data = {
            accessToken: authToken,
            postdata: postdata
        }
        dispatch(postCertificates(data))
        dispatch(createNotification({
            message: `Create Certification for ${selectedDuaration === '6month' ? '6 Month' : '1 Year '} `,
            user_id: user.user.id,
            accessToken: authToken,
            is_admin: false
        }))
        dispatch(fetchCertificates({ accessToken: authToken }));
        closebutton()
    }

    const handleDownload = (item) => {
        try {
            const parsedItem = JSON.parse(item);
            console.log(parsedItem[0]?.sections[0]?.column[0]?.row[1]?.content[0]?.url,'hggggsgggggg')
            const fileUrl = parsedItem[0]?.sections[0]?.column[0]?.row[1]?.content[0]?.url;
            if (fileUrl) {
                const xhr = new XMLHttpRequest();
                xhr.open('GET', fileUrl, true);
                xhr.responseType = 'blob';
                
                xhr.onload = function () {
                    if (xhr.status === 200) {
                        const blob = xhr.response;
                        const url = window.URL.createObjectURL(blob);
                        const link = document.createElement('a');
                        link.href = url;
                        link.download = 'downloaded_image.jpg';
                        document.body.appendChild(link);
                        link.click();
                        document.body.removeChild(link);
                        window.URL.revokeObjectURL(url);
                    } else {
                        console.error('Failed to download the file.');
                    }
                };
    
                xhr.onerror = function () {
                    console.error('Failed to download the file.');
                };
                
                xhr.send();
            } else {
                console.error('File URL not found in the JSON data.');
            }
        } catch (error) {
            console.error('Error parsing item string:', error);
        }
    };

    return (
        <div>
            <Navbar />
            <Header />
            <div className='wrapper'>
                <div className='boxAppBoard boxWhite'>
                    <div className='boardHeader'>
                        <div className='boardHeadLeft'>
                            <h2>In Process Applications</h2>
                        </div>
                        <div className='boardHeadRight'>
                            <div className='searchForm'>
                                <input type='text' placeholder='input search text' onChange={handleSearchInputChange} value={searchQuery} />
                                <button><img src={iconSearch} alt='' /></button>
                            </div>
                            <div className='datePicker'>
                            </div>
                        </div>
                    </div>
                    <div className='boardContent'>
                        <div className='boardThead'>
                            <div className='boardCol w-85'>
                                S.NO.
                            </div>
                            <div className='boardCol w-165'>
                                Application No.
                            </div>
                            <div className='boardCol w-adj3'>
                                Application Name
                            </div>
                            <div className='boardCol w-165'>
                                Submitted Date
                            </div>
                            <div className='boardCol w-165'>
                                Certification
                            </div>
                            <div className='boardCol w-110'>
                                Downloads
                            </div>
                        </div>
                        {filteredApplication.sort((a, b) => b.applicationId - a.applicationId).map((item, index) => (
                            <div className={`boardTrow ${(currentPage === 1 ? currentPage : ((currentPage - 1) * 10) + 1) <= index + 1 && index < (currentPage === 1 ? 10 : (currentPage * 10)) ? '' : 'd-none'} `} key={item.id}>
                                <div className='boardCol w-85'>
                                    {index + 1}
                                </div>
                                <div className='boardCol w-165'>
                                    {`GB-000000${item.applicationId}`}
                                </div>
                                <div className='boardCol w-adj3'>
                                    <div className='boardTitle'>
                                        <strong>{item.name}</strong>
                                        <span>{item.form}</span>
                                    </div>
                                </div>
                                <div className='boardCol w-165'>
                                    {item?.updated_at ? item.updated_at.slice(0, 10) : ''}
                                </div>
                                <div className='boardCol w-165 '>
                                    {certificates.data.filter(cert => cert.application_id === item.applicationId).length === 0 && (
                                        <div className='boardDownload' onClick={() => { setSelectedItem(item); handlecertification(item.state) }}>
                                            <img style={{ marginLeft: '30px' }} src={iconFilecertification} alt='' />
                                        </div>
                                    )}
                                </div>
                                <div className='boardCol w-110'>
                                    <div className='boardActions' onClick={() => handleDownload(item.json_data)}>
                                        <img src={iconFileDownload} alt='' />
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>

                    <ReactModal
                        isOpen={modalIsOpen}
                        onRequestClose={closebutton}
                        contentLabel="Example Modal"
                    >
                        <div style={{ position: 'absolute', top: 0, left: 100, width: '100%', height: '100%', backgroundColor: 'rgba(0, 0, 0, 0.5)', zIndex: 999 }}>
                            <div style={{ backgroundColor: '#fff', width: '50%', margin: 'auto', marginTop: '10%', padding: '20px', borderRadius: '5px' }}>
                                <h1 style={{ marginBottom: '20px' }}>Do you want certification?</h1>
                                <div className="boardHeadRight">
                                    <div>
                                        <select
                                            className="form-select"
                                            style={{ width: '100%' }}
                                            value={selectedDuaration}
                                            onChange={(e) => { setselectedDuaration(e.target.value) }}
                                        >
                                            <option selected value="All">Select Duaration</option>
                                            <option key='1' value="6month">6 Month</option>
                                            <option key='2' value="1year">1 Year</option>
                                        </select>
                                    </div>
                                </div>
                                <div style={{ textAlign: 'right', marginTop: '20px' }}>
                                    <button style={{ marginRight: '10px', padding: '5px 10px', backgroundColor: '#ccc', border: 'none', borderRadius: '3px', cursor: 'pointer' }} onClick={(e) => { closebutton(e) }}>Close</button>
                                    <button onClick={(e) => { confirmcertification(e) }} style={{ padding: '5px 10px', backgroundColor: '#007bff', color: '#fff', border: 'none', borderRadius: '3px', cursor: 'pointer' }}>Confirm</button>
                                </div>
                            </div>
                        </div>
                    </ReactModal>


                    <Pagination
                        currentPage={currentPage}
                        setCurrentPage={setCurrentPage}
                        totalPages={getTotalPages}
                    />
                </div>
            </div>
        </div>
    )
}

export default ApprovedApp;
