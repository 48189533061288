// reducers/formsSlice.js
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { showError, showSuccess } from "../../helpers/toast";
import axios from "axios";

const API = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_URL,
});

API.interceptors.response.use(
  (response) => {
    return Promise.resolve(response);
  },
  (error) => {
    showError(error.response?.data?.error ?? error.message);
    return Promise.reject(error);
  }
);

const initialState = {
  fetchLoading: false,
  fetchError: null,
  message: null,
  data: [],
};

export const fetchForms = createAsyncThunk("fetchForms", async (data) => {
  try {
    const config = {
      headers: {
        "ngrok-skip-browser-warning": "69420",
        "Content-Type": "application/json",
        Authorization: `${data.accessToken}`,
      },
    };
    const response = await API.get("/forms", config);
    return response.data;
  } catch (error) {
    throw new Error("Failed to load forms");
  }
});

export const deleteForms = createAsyncThunk("deleteForms", async (data) => {
  try {
    const config = {
      headers: {
        "ngrok-skip-browser-warning": "69420",
        "Content-Type": "application/json",
        Authorization: `${data.accessToken}`,
      },
    };

    const response = await API.delete(`/forms/${data.id}`, config);
    showSuccess("Form deleted!");
    return response.data;
    // return
  } catch (error) {
    throw new Error("Failed to delete form");
  }
});

export const uploadFile = createAsyncThunk("uploadFile", async (data) => {
  const { accessToken, payload } = data;

  try {
    const config = {
      headers: {
        "ngrok-skip-browser-warning": "69420",
        "Content-Type": "multipart/form-data",
        Authorization: `${accessToken}`,
      },
    };
    const response = await API.post("/forms/upload-file", payload, config);
    showSuccess("File uploaded successfully!");
    return response.data;
  } catch (error) {
    throw new Error("Failed to upload file");
  }
});

const formsSlice = createSlice({
  name: "forms",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchForms.pending, (state) => {
        state.fetchLoading = true;
      })
      .addCase(fetchForms.fulfilled, (state, action) => {
        state.fetchLoading = false;
        state.data = action.payload;
        state.fetchError = null;
      })
      .addCase(fetchForms.rejected, (state, action) => {
        state.fetchLoading = false;
        state.fetchError = action.error.message;
        state.data = null;
        state.message = null;
      });
  },
});

export default formsSlice.reducer;
