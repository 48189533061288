import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';

import Navbar from '../../navbar';
import Header from '../../header';

import { createCategories } from '../../../store/reducers/categoriesSlice';

const CreateCategories = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const accessToken = useSelector((state) => state.auth.user.access_token);

  // Define validation schema using Yup
  const validationSchema = Yup.object().shape({
    categoryName: Yup.string().required('Category Name is required'),
  });

  // Initialize Formik
  const formik = useFormik({
    initialValues: {
      categoryName: '',
    },
    validationSchema,
    onSubmit: async (values, { resetForm }) => {
      // Dispatch the createCategory action
      dispatch(createCategories({ accessToken, name: values.categoryName }));

      // Optionally navigate to another page after category creation
      navigate('/categories'); // Uncomment and replace with the desired navigation

      // Reset the form after submission
      resetForm();
    },
  });

  return (
    <div>
      <Navbar />
      <Header />

      <div className="wrapper">
        <div className="card">
          <div className="card-header">
            <h3 className="mb-0">Create Category</h3>
          </div>
          <div className="card-body">
            <form onSubmit={formik.handleSubmit}>
              <div className="my-3">
                <label htmlFor="categoryName" className="form-label">
                  Category Name:
                </label>
                <input
                  type="text"
                  id="categoryName"
                  name="categoryName"
                  className={`form-control ${
                    formik.touched.categoryName && formik.errors.categoryName ? 'is-invalid' : ''
                  }`}
                  value={formik.values.categoryName}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                {formik.touched.categoryName && formik.errors.categoryName && (
                  <div className="invalid-feedback">{formik.errors.categoryName}</div>
                )}
              </div>

              <div className="text-end">
                <button type="submit" className="btn btn-primary">
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateCategories;
