import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import Modal from 'react-modal';
import { useNavigate } from 'react-router-dom';
import iconFileEdit from "../../../assets/media/icon-file-edit.svg";
import iconFileDelete from "../../../assets/media/icon-file-delete.svg";
import iconSearch from "../../../assets/media/icon-search.svg";

import { Link } from 'react-router-dom';
import Navbar from '../../navbar';
import Header from '../../header';

import { fetchCategories, deleteCategories } from '../../../store/reducers/categoriesSlice';

const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
    },
};

const Categories = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const categories = useSelector((state) => state.categories.data);
    const [filteredCategories, setFilteredCategories] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [isModalOpen, setModalOpen] = useState(false);
    const accessToken = useSelector((state) => state.auth.user.access_token);
    const [searchTerm, setSearchTerm] = useState("");

    const handleConfirm = async () => {
        await dispatch(deleteCategories({ accessToken: accessToken, id: selectedCategory?.id }))
        await dispatch(fetchCategories({ accessToken: accessToken }))
        setModalOpen(false);
    };

    const handleCancel = () => {
        setModalOpen(false);
    };

    const remove = (category) => {
        setSelectedCategory(category)
        setModalOpen(true);
    };

    useEffect(() => {
        dispatch(fetchCategories({ accessToken: accessToken }))
    }, [])

    useEffect(() => {
        const filtered = categories.filter(category =>
            category.name.toLowerCase().includes(searchTerm.toLowerCase())
        ).sort((a, b) => b.id - a.id)
        setFilteredCategories(filtered);
    }, [searchTerm, categories]);

    return (
        <div>
            <Navbar />
            <Header />

            <div className='wrapper'>
                <div className='boxAppBoard boxWhite'>
                    <div className='boardHeader'>
                        <div className='boardHeadLeft'>
                            <h2>Categories</h2>
                        </div>
                        <div className='boardHeadRight'>
                        <div className='searchForm mx-3'>
                                <input type='text' placeholder='input search text' value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)} />
                                <button><img src={iconSearch} alt='' /></button>
                            </div>
                            <button onClick={() => navigate('/categories/create')} className='btn btn-primary'>
                                +
                            </button>
                           
                        </div>
                    </div>
                    <div className='boardContent'>
                        <div className='boardThead'>
                            <div className='boardCol w-85'>
                                S.NO.
                            </div>
                            <div className='boardCol w-165'>
                                Category Name
                            </div>
                            <div className='boardCol w-110'>
                            </div>
                        </div>
                        {
                            filteredCategories.map((value) => (
                                <>
                                    <div className='boardTrow'>
                                        <div className='boardCol w-85'>
                                            {value.id}
                                        </div>
                                        <div className='boardCol w-165'>
                                            {value.name}
                                        </div>
                                        <div className='boardCol w-110'>
                                            <div className='boardActions'>
                                                <Link to={`/categories/${value.id}/edit`}>
                                                    <img src={iconFileEdit} alt='' />
                                                </Link>
                                                <a href='#' onClick={() => remove(value)}><img src={iconFileDelete} alt='' /></a>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            ))
                        }
                    </div>
                </div>
            </div>

            <Modal
                isOpen={isModalOpen}
                onRequestClose={() => setModalOpen(false)}
                contentLabel="Confirmation Modal"
                style={customStyles}
            >
                <p>Are you sure you want to remove the item?</p>
                <div className='mt-5 d-flex justify-content-end'>
                    <button onClick={handleConfirm} className='btn btn-primary me-2'>Yes, Remove</button>
                    <button onClick={handleCancel} className='btn btn-danger'>Cancel</button>
                </div>
            </Modal>
        </div>
    )
}
export default Categories
