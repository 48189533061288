import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import iconFileDelete from "../../../assets/media/icon-file-delete.svg";
import iconSearch from "../../../assets/media/icon-search.svg";
import iconCaretLeft from "../../../assets/media/icon-caret-left.svg";
import iconCaretRight from "../../../assets/media/icon-caret-right.svg";
import iconCaretDown from "../../../assets/media/icon-caret-down.svg";
import Avatar01 from "../../../assets/media/avatar-01.png";

import Navbar from "../../navbar";
import Header from "../../header";

import { useDispatch, useSelector } from "react-redux";
import { addEmployee, adduser } from "../../../store/reducers/employeeSlice.js";
import { fetchEmployee } from "../../../store/reducers/employeeSlice.js";
import Pagination from "../dashboard/appPagination";
import userImagePlaceholder from "../../../assets/media/avatar2.jpg";
import { deleteUser, getallUser } from "../../../store/reducers/userSlice.js";

const AppUsers = () => {
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const user = useSelector((state) => state.auth.user);
  const getallUserdata = useSelector((state) => state.userdata.getalluserdata);
  const employee = useSelector((state) => state.employee);
  const createLoading = useSelector((state) => state.employee.createLoading);
  const fetchLoading = useSelector((state) => state.employee.fetchLoading);
  const [searchQuery, setSearchQuery] = useState("");
  const [filtereduser, setFilteredUser] = useState([]);

  const users = employee?.users;
  const authToken = user.access_token;

  const initialValues = {
    full_name: "",
    national_id_number: "",
    email: "",
    phone: "",
    roles: [],
  };

  const validationSchema = Yup.object({
    full_name: Yup.string(),
    national_id_number: Yup.string(),
    email: Yup.string()
      .email("Invalid email address")
      .required("Email is required"),
    phone: Yup.string(),
    roles: Yup.array()
      .of(Yup.string())
      .min(1, "Select atleast one role")
      .required("Select atleast one role"),
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      const { email, full_name, national_id_number, phone, roles } = values;
      // console.log({ email, full_name, national_id_number, phone, roles })
      dispatch(
        addEmployee({
          userData: {
            full_name,
            national_id_number,
            email,
            phone,
            roles,
            is_admin: 1,
            user_role: "admin",
          },
          accessToken: authToken,
        })
      );
      dispatch(fetchEmployee({ accessToken: authToken }));
      dispatch(getallUser(user.access_token));
    },
  });

  const {
    values,
    handleChange,
    handleSubmit,
    handleBlur,
    errors,
    setFieldValue,
  } = formik;

  const filtered = (search = "") => {
    const finalArray = users?.filter((item) => {
      for (const key in item) {
        if (
          typeof item[key] === "string" &&
          item[key].toLowerCase().includes(search.toLowerCase())
        ) {
          return true;
        }
      }
      return false;
    });

    setData(finalArray);
    return finalArray;
  };

  const getTotalPages = () => {
    if (Number.isInteger(data?.length / 10)) return data?.length / 10;
    return Math.floor(data?.length / 10) + 1;
  };
  const handleInputChange = (event) => {
    setSearchTerm(event.target.value);
  };
  useEffect(() => {
    filtered();
  }, [users]);

  // useEffect(() => {
  //     const performSearch = async () => {
  //         const dataSearch = await filtered(searchTerm);
  //         setData(dataSearch);
  //     }
  //     const debounceTimer = setTimeout(() => {
  //         performSearch();
  //     }, 500);
  //     return () => clearTimeout(debounceTimer);
  // }, [searchTerm])

  useEffect(() => {
    dispatch(fetchEmployee({ accessToken: authToken }));
    dispatch(getallUser(user.access_token));
  }, [authToken, dispatch]);

  const roleOptions = [
    {
      value: "create_forms",
      label: "Create Forms",
    },
    {
      value: "add_delete_users",
      label: "Add/Delete Users",
    },
    {
      value: "create_discrepencies",
      label: "Create Discrepencies",
    },
    {
      value: "issue_certificates",
      label: "Issue Certificates",
    },
  ];

  const handleSearchInputChange = (e) => {
    setSearchQuery(e.target.value);
  };
  useEffect(() => {
    setFilteredUser(
      getallUserdata
        .filter((item) => {
          return (
            item.is_deleted != true &&
            item.is_admin &&
            Object.values(item).some(
              (value) =>
                value &&
                value
                  .toString()
                  .toLowerCase()
                  .includes(searchQuery.toLowerCase())
            )
          );
        })
        .sort((a, b) => b.id - a.id)
    );
  }, [getallUserdata, searchQuery]);

  const deleteuserdata = (id) => {
    const config = {
      accessToken: authToken,
      user_id: id,
    };
    dispatch(deleteUser({ configdata: config }));
    dispatch(getallUser(user.access_token));
  };

  return (
    <div>
      <Navbar />
      <Header />
      <div className="wrapper">
        <div className="boxAppBoard boxWhite">
          <div className="boardHeader noMg">
            <div className="boardHeadLeft">
              <h2>Users</h2>
            </div>
          </div>
        </div>
        <div className="empAdd">
          <h3 className="noMg">Add User </h3>
          <form className="addEmployee-form" onSubmit={handleSubmit}>
            <div className="row">
              <div className="col-12 col-lg-4">
                <div className="fieldWrap">
                  <label>Full Name</label>
                  <input
                    name="full_name"
                    type="text"
                    placeholder="Marvin McKinney"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.full_name}
                    error={errors.full_name}
                  />
                </div>
              </div>
              <div className="col-12 col-lg-4">
                <div className="fieldWrap">
                  <label>National Identity Number</label>
                  <input
                    name="national_id_number"
                    type="text"
                    placeholder="Marvin McKinney"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.national_id_number}
                    error={errors.national_id_number}
                  />
                </div>
              </div>
              <div className="col-12 col-lg-4">
                <div className="fieldWrap">
                  <label>Email Address</label>
                  <input
                    name="email"
                    type="text"
                    placeholder="Marvin McKinney"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.email}
                    error={errors.email}
                  />
                </div>
              </div>
              <div className="col-12 col-lg-4">
                <div className="fieldWrap">
                  <label>Phone No.</label>
                  <input
                    name="phone"
                    type="text"
                    placeholder="(702) 555-0122"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.phone}
                    error={errors.phone}
                  />
                </div>
              </div>
              <div className="d-flex align-items-end justify-content-between mt-5">
                <div className="boardHeadRight">
                  <div className="mb-2 d-flex align-items-center">
                    <h6 className="mb-0">Assign Roles</h6>{" "}
                    {errors.roles && (
                      <div className="mx-2 error-message">{errors.roles}</div>
                    )}
                  </div>

                  <div className="boardFilter">
                    {roleOptions.map((item) => (
                      <div class="form-check form-check-inline">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          checked={values.roles.includes(item.value)}
                          name="roles"
                          onChange={(e) => {
                            if (e.target.checked) {
                              setFieldValue("roles", [
                                ...values.roles,
                                item.value,
                              ]);
                            } else {
                              setFieldValue(
                                "roles",
                                values.roles.filter((r) => r !== item.value)
                              );
                            }
                          }}
                          onBlur={handleBlur}
                          value={item.value}
                        />
                        <label class="form-check-label">{item.label}</label>
                      </div>
                    ))}
                    {/* <div class="form-check form-check-inline">
                                            <input class="form-check-input" type="checkbox" value="All" checked />
                                            <label class="form-check-label">All</label>
                                        </div>
                                        <div class="form-check form-check-inline">
                                            <input class="form-check-input" type="checkbox" value="In Process" />
                                            <label class="form-check-label">In Process</label>
                                        </div>
                                        <div class="form-check form-check-inline">
                                            <input class="form-check-input" type="checkbox" value="Discrepancies" />
                                            <label class="form-check-label">Discrepancies</label>
                                        </div>
                                        <div class="form-check form-check-inline">
                                            <input class="form-check-input" type="checkbox" value="Pending" />
                                            <label class="form-check-label">Pending</label>
                                        </div>
                                        <div class="form-check form-check-inline">
                                            <input class="form-check-input" type="checkbox" value="Approved" />
                                            <label class="form-check-label">Approved</label>
                                        </div> */}
                  </div>
                </div>

                <div className="">
                  <div className="fieldWrap noLabel">
                    <button type="submit" disabled={createLoading}>
                      {createLoading ? (
                        <>
                          <div
                            class="text-xs spinner-border text-light"
                            style={{ width: "1rem", height: "1rem" }}
                            role="status"
                          ></div>
                          <span class="sr-only ml-5"> Loading...</span>
                        </>
                      ) : (
                        "Add User"
                      )}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
        <div className="boxAppBoard boxWhite mt-5">
          <div className="boardHeader">
            <div className="boardHeadLeft">
              <h2>All Users</h2>
            </div>
            <div className="boardHeadRight">
              <div className="searchForm">
                <input
                  type="text"
                  placeholder="input search text"
                  onChange={handleSearchInputChange}
                  value={searchQuery}
                />
                <button>
                  <img src={iconSearch} alt="" />
                </button>
              </div>
              <div className="datePicker"></div>
            </div>
          </div>
          <div className="table-responsive">
            <table className="table">
              <thead className="boardTheadcompany">
                <tr>
                  <th className="boardCol w-adj2">S.NO.</th>
                  <th className="boardCol w-adj2">User Id</th>
                  <th className="boardCol w-adj2">Name</th>
                  <th className="boardCol w-adj2">Email</th>
                  <th className="boardCol w-adj2">Phone No.</th>
                  <th className="boardCol w-adj2">Create Date</th>
                  <th className="boardCol w-adj2">Status</th>
                  <th className="boardCol w-adj2"></th>
                </tr>
              </thead>
              <tbody>
                {fetchLoading ? (
                  <tr>
                    <td className="text-center" colSpan="8">
                      <div className="d-flex justify-content-center my-4">
                        <div
                          className="spinner-border text-primary"
                          role="status"
                        >
                          <span className="visually-hidden">Loading...</span>
                        </div>
                      </div>
                    </td>
                  </tr>
                ) : (
                  <>
                    {!!filtereduser?.length ? (
                      filtereduser.map(
                        (item, index) =>
                          item.is_admin &&
                          item.is_deleted != true && (
                            <tr
                              key={index}
                              className={`boardTrowcompany ${
                                (currentPage === 1
                                  ? currentPage
                                  : (currentPage - 1) * 10 + 1) <=
                                  index + 1 &&
                                index <
                                  (currentPage === 1 ? 10 : currentPage * 10)
                                  ? ""
                                  : "d-none"
                              } `}
                            >
                              <td className="boardCol w-adj2">{index + 1}</td>
                              <td className="boardCol w-adj2">
                                {" "}
                                {`GB-000000${item.id}`}
                              </td>
                              <td className="boardCol w-adj2">
                                <div className="boardTitle widAvatar">
                                  <img
                                    className="avatar"
                                    src={item.image || userImagePlaceholder}
                                    alt=""
                                  />
                                  <strong>{item.full_name}</strong>
                                  <span>{item.national_id_number || "--"}</span>
                                </div>
                              </td>
                              <td className="boardCol w-adj2">
                                <a href={`mailto:${item.email}`}>
                                  {item.email}
                                </a>
                              </td>
                              <td className="boardCol w-adj2">
                                <a href={`tel:${item.phone}`}>{item.phone}</a>
                              </td>
                              <td className="boardCol w-adj2">
                                {item.created_at?.slice(0, 10)}
                              </td>
                              <td className="boardCol w-adj2">
                                {item.active ? "Completed" : "Process"}
                              </td>
                              <td className="boardCol w-adj2">
                                <img
                                  style={{ cursor: "pointer" }}
                                  onClick={() => {
                                    deleteuserdata(item.id);
                                  }}
                                  src={iconFileDelete}
                                  alt=""
                                />
                              </td>
                            </tr>
                          )
                      )
                    ) : (
                      <tr>
                        <td colSpan="8" className="text-center">
                          <h2 className="text-danger my-4 py-4">
                            Users not Found
                          </h2>
                        </td>
                      </tr>
                    )}
                  </>
                )}
              </tbody>
            </table>
          </div>

          {/* <div className='boardFooter'>
                        <div className='pagination'>
                            <Link className='disabled' to={'/'}><img src={iconCaretLeft} alt='' /></Link>
                            <Link to={'/'}>1</Link>
                            <Link to={'/'}>4</Link>
                            <Link to={'/'}>5</Link>
                            <Link className='active' to={'/'}>6</Link>
                            <Link to={'/'}>7</Link>
                            <Link to={'/'}>8</Link>
                            <Link to={'/'}>50</Link>
                            <Link to={'/'}><img src={iconCaretRight} alt='' /></Link>
                            <Link className='wAuto' to={'/'}>10 / page <img className='ml-6' src={iconCaretDown} alt='' /></Link>
                            <span>Goto</span>
                            <input type='text' />
                        </div>
                    </div> */}
          <Pagination
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            totalPages={getTotalPages}
          />
        </div>
      </div>
    </div>
  );
};
export default AppUsers;
