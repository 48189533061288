import React ,{useState,useEffect}from 'react';
import Navbar from '../../navbar';
import Header from '../../header';
import Form1 from '../../forms/pages/form_1'
import Form4 from '../../forms/pages/form_4'
import Form20 from '../../forms/pages/form_20';
import Form21 from '../../forms/pages/form_21';
import Form22 from '../../forms/pages/form_22';
import Form5 from '../../forms/pages/form_5';
import Form6 from '../../forms/pages/form_6';
import FormHeader from '../../forms/formHeader/formHeader'
import { useDispatch, useSelector } from 'react-redux';
import { createFormMandatoryField } from '../../../store/reducers/formMandatoryFieldsSlice'; // Adjust path as needed


const AppFormCreation = () => {

    const [formIds, setFormIds] = useState([]);
    const [mandatoryFields,setMandatoryFields] = useState([])
    const [pageIds,setPageIds] = useState([]);
    const [currentPage,setCurrentPage] = useState(1);
    const user = useSelector((state) => state.auth.user);
    const {category,applicationName,formId} = useSelector((state)=>state.counter.createFormData);
    const dispatch = useDispatch();

    useEffect(() => {
        const elements = document.querySelectorAll('[id^="form"]');
        const ids = Array.from(elements)
          .filter(element => /^form\d+_\d+$/.test(element.id))
          .map(element => element.id);
        setFormIds(ids);
        setPageIds(getPageIds());
      }, []);
   
    
      // Use useEffect to dynamically add event listeners
      useEffect(() => {
        attachEventHandlers();
        console.log(category);
        // Cleanup event listeners on unmount
        return () => {
          formIds.forEach((id) => {
            const inputField = document.getElementById(id);
            if (inputField) {
              inputField.removeEventListener('click', handleInputChange);
            }
          });
        };
      }, [formIds]);

      useEffect(()=>{
        // console.log(pageIds);
        if(pageIds.length>0){
            pageIds.forEach(id => {
                if(id.replace(/^page/, '')!=currentPage){
                    const pageDiv = document.getElementById(id);
                    if (pageDiv) {
                      pageDiv.style.display = "none";
                    }
                }    
              });
        }
      },[pageIds])

      const handleInputChange = (event) => {
        if(event.target.style.backgroundColor==="rgb(255, 0, 0)"){
            setMandatoryFields((prevFields) => prevFields.filter((id) => id !== event.target.id));
            event.target.style.backgroundColor = '';
        }else{
            event.target.style.backgroundColor = '#ff0000'; // Change background color to red
            setMandatoryFields((prevFields) => [...prevFields, event.target.id]);
        }
      };
    
      // Function to attach event handlers
      const attachEventHandlers = () => {
        formIds.forEach((id) => {
          const inputField = document.getElementById(id);
          if (inputField) {
            // console.log("Event Listner Attached ",id);
            inputField.addEventListener('click', handleInputChange);
          }
        });
      };

      const handleCreate = () => {
        const newField = {
          user_id: 1,
          form_id: formId,
          company_id: 1,
          mandatory_fields:mandatoryFields,
        };
        const authToken = user.access_token;
        dispatch(createFormMandatoryField({ data: newField, authToken }));
      };

      const getPageIds = () => {
        const elements = document.querySelectorAll('[id^="page"]');
        const ids = Array.from(elements)
          .filter(element => /^page\d+$/.test(element.id))
          .map(element => element.id);
        return ids;
      };

      const previousPage = () =>{
        if(currentPage-1>=1){
          hideDiv(currentPage);
          showDiv(currentPage-1);
          setCurrentPage(currentPage-1);
        }
      }
    
      const nextPage = () =>{
        if(currentPage+1<=pageIds.length){
          hideDiv(currentPage);
          showDiv(currentPage+1);
          setCurrentPage(currentPage+1);
        }
      }
      const hideDiv=(pageNo)=>{
        const pageDiv = document.getElementById("page"+pageNo);
        pageDiv.style.display = "none"; 
      }

      const showDiv=(pageNo)=>{
        const pageDiv = document.getElementById("page"+pageNo);
        pageDiv.style.display = ""; 
      }

    return (
        <div>
            <Navbar />
            <Header />
            <div className='wrapper'>

                <div className='profHeader'>
                    <div className='profHeadLeft'>
                        <h2>{category}</h2>
                        <span>Select mandatory fields*</span>
                    </div>
                    
                    <div >
                      <a href='#' className='buttonPrimary' onClick={handleCreate}>Save</a>
                    </div>
                </div>
                <div className='sp20'></div>
             <FormHeader applicationName={applicationName} nextPage={nextPage} previousPage={previousPage} currentPage={currentPage} totalPages={pageIds.length}/>
                {/* <Form4/> */}
                {/* <Form20/> */}
                {/* <Form21/> */}
                {/* <Form22/> */}
                {/* <Form5/> */}
<Form6 />            
            </div>

        </div>
    )
}
export default AppFormCreation