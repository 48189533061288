// reducers/categoriesSlice.js
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { showError, showSuccess } from "../../helpers/toast";
import axios from "axios";

const API = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_URL,
});
API.interceptors.response.use(
  (response) => {
    return Promise.resolve(response);
  },
  (error) => {
    showError(error.response?.data?.error ?? error.message);
    return Promise.reject(error);
  }
);

const initialState = {
  fetchLoading: false,
  fetchError: null,
  message: null,
  data: [],
};

export const createCategories = createAsyncThunk(
  "/createCategories",
  async (data) => {
    try {
      const config = {
        headers: {
          Authorization: `${data.accessToken}`,
          "Content-Type": "application/json",
        },
      };

      const response = await API.post(
        "/categories",
        { name: data.name },
        config
      );
      showSuccess("Category created!");
      return response.data;
    } catch (error) {
      console.log({ error });
      throw new Error(
        error?.response?.data?.message || "Failed to add category"
      );
    }
  }
);

export const fetchCategories = createAsyncThunk(
  "fetchCategories",
  async (data) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `${data.accessToken}`,
        },
      };
      const response = await API.get("/categories", config);
      return response.data;
    } catch (error) {
      throw new Error("Failed to load categories");
    }
  }
);

export const deleteCategories = createAsyncThunk(
  "deleteCategories",
  async (data) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `${data.accessToken}`,
        },
      };

      const response = await API.delete(`/categories/${data.id}`, config);
      showSuccess("Category deleted!");
      return response.data;
      // return
    } catch (error) {
      throw new Error("Failed to delete form");
    }
  }
);

export const updateCategories = createAsyncThunk(
  "categories/update",
  async (data) => {
    try {
      const config = {
        headers: {
          Authorization: `${data.accessToken}`,
          "Content-Type": "application/json",
        },
      };

      const response = await API.put(
        `/categories/${data.id}`,
        { name: data.name },
        config
      );
      showSuccess("Category updated!");
      return response.data;
    } catch (error) {
      throw new Error("Failed to update category");
    }
  }
);

export const getCategoriesById = createAsyncThunk(
  "categories/getById",
  async (data) => {
    try {
      const config = {
        headers: {
          Authorization: `${data.accessToken}`,
          "Content-Type": "application/json",
        },
      };

      const response = await API.get(`/categories/${data.id}`, config);
      return response.data;
    } catch (error) {
      throw new Error("Failed to get category details");
    }
  }
);

const categoriesSlice = createSlice({
  name: "categories",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchCategories.pending, (state) => {
        state.fetchLoading = true;
      })
      .addCase(fetchCategories.fulfilled, (state, action) => {
        state.fetchLoading = false;
        state.data = action.payload;
        state.fetchError = null;
      })
      .addCase(fetchCategories.rejected, (state, action) => {
        state.fetchLoading = false;
        state.fetchError = action.error.message;
        state.data = null;
        state.message = null;
      });
  },
});

export default categoriesSlice.reducer;
