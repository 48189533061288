import React from 'react';
import { IoChevronBackSharp, IoChevronForwardSharp } from "react-icons/io5"

const FormHeader =(props)=>{
    const handleGoPrev = (event) => {
        //change page
        console.log("Go previous");
        props.previousPage(event);

      }
      const handleGoForw = (event) => {
         //change page
         props.nextPage(event);
         console.log("Go Forward");
      }
    return(
        <>
           <div className='AppFormBar noShadow'>
                    <div className='title'>
                        <input
                            value={props.applicationName}
                            style={{
                                width: "auto",
                                background: "#323639",
                                border: "solid 1px #ccc",
                                borderRadius: "5px",
                                padding: "5px",
                                fontWeight: "600",
                                fontSize: "16px",
                                lineHeight: "22px",
                                color: "#fff",
                            }} />
                    </div>
                    <div className='pagination'>
                        <div className='pageNum d-flex align-items-center'>
                            <IoChevronBackSharp onClick={handleGoPrev} />
                            <span className='pageCurrent d-flex align-items-center justify-content-center' style={{ width: 50, height: 50, border:0, textAlign:'center', color:'#fff' }} >{props.currentPage}</span> <span className='slash'>{`/`}<span style={{ marginLeft: 10 }}></span></span>{props.totalPages}
                            <IoChevronForwardSharp onClick={handleGoForw} />
                        </div>
                    </div>
                </div>
        </>
    )
}

export default FormHeader;