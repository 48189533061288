import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { IoChevronBackSharp, IoChevronForwardSharp } from 'react-icons/io5';
import Navbar from '../../navbar';
import Header from '../../header';
import { fetchApplicationWithId, updateApplication } from '../../../store/reducers/applicationSlice';
import FormContent from './FormContent';
import { AiOutlineEye } from 'react-icons/ai';
import { formatDate } from '../dashboard/aapDashboard';
import Pagination from '../dashboard/appPagination';
import classes from './style.module.css';
import iconSearch from '../../../assets/media/icon-search.svg';
import { getstate } from '../../../store/reducers/stateSlice';
import { fetchCategories } from '../../../store/reducers/categoriesSlice';
import { postCertificates } from '../../../store/reducers/certificateSlice';
import Modal from 'react-modal';
import { createNotification } from '../../../store/reducers/notificationSlice';

const ViewApp = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const params = useParams();
    const user = useSelector((state) => state.auth.user);
    const authToken = user.access_token;
    const { currentApp } = useSelector((state) => state.applications);
    const { createFormData } = useSelector((state) => state.counter);
    const accessToken = user.access_token;
    const [formData, setFormData] = useState([]);
    const [currentPage, setCurrentPage] = useState('');
    const [activeDiscrepancy, setActiveDiscrepancy] = useState(null);
    const [discrepancy, setDiscrepancy] = useState('');
    const applicationdata = useSelector((state) => state.applications.currentApp);
    const status = useSelector((state) => state.status.status);
    const categories = useSelector((state) => state.categories.data);
    const [selectedDuaration, setselectedDuaration] = useState('')
    const [modalIsOpen, setIsOpen] = React.useState(false);

    // const handleUpdate = async () => {


    //     dispatch(updateApplication({
    //         accessToken,
    //         id: currentApp?.id,
    //         state: 6,
    //         json_data: JSON.stringify(formData)
    //     })).then(e => {
    //         console.log('e', e)
    //         dispatch(createNotification({
    //             message: 'Please check your application discrepancies',
    //             userId: e?.payload?.user_id,
    //             accessToken: authToken,
    //             is_admin: true
    //         }))
    //     })
    // }

    // const handleChangeDiscrepancy = ({ ...params } = {}) => {
    //     if ((activeDiscrepancy || params?.activeDiscrepancy) && formData) {
    //         const updatedFormData = formData.map((page) => ({
    //             ...page,
    //             sections: page.sections.map((section) => ({
    //                 ...section,
    //                 column: section.column.map((col) => ({
    //                     ...col,
    //                     row: col.row.map((row) => ({
    //                         ...row,
    //                         content: row.content.map((content) => {
    //                             if (
    //                                 (content?.id === activeDiscrepancy?.id) ||
    //                                 (content.id === params?.activeDiscrepancy?.id)
    //                             ) {
    //                                 if (params?.activeDiscrepancy) {
    //                                     if (content?.discrepancy) setDiscrepancy(content?.discrepancy);
    //                                     else setDiscrepancy('');
    //                                 }

    //                                 return {
    //                                     ...content,
    //                                     discrepancy: discrepancy,
    //                                 };
    //                             }
    //                             return content;
    //                         }),
    //                     })),
    //                 })),
    //             })),
    //         }));

    //         if (!params?.activeDiscrepancy) setFormData(updatedFormData);
    //     }
    // };

    // useEffect(() => {
    //     handleChangeDiscrepancy({ activeDiscrepancy: true, activeDiscrepancy: activeDiscrepancy });
    // }, [activeDiscrepancy]);

    // useEffect(() => {
    //     handleChangeDiscrepancy();
    // }, [discrepancy]);

    // useEffect(() => {
    //     setCurrentPage(formData[0]?.id);
    // }, [formData]);

    // useEffect(() => {
    //     dispatch(fetchApplicationWithId({ id: params?.id, accessToken }));
    // }, []);

    // useEffect(() => {
    //     if (!!currentApp?.json_data && currentApp?.json_data?.length > 0) {
    //         setFormData(JSON.parse(currentApp.json_data))
    //     }
    // }, [currentApp])

    // // console.log({formData})



    const statusvalue = () => {
        const data = {
            accessToken: accessToken,
            id: params.id,
        };
        dispatch(fetchApplicationWithId(data));
        dispatch(getstate(accessToken));
        dispatch(fetchCategories({ accessToken: accessToken }))
    };

    const selecteddata = async (e) => {
        const filterstatus = await status.filter((item) => item.name === e);
        console.log(filterstatus, 'llllllllllllllllllllllllllllllllllll')
        await console.log(e)
        if (e === 'approved') {
            setIsOpen(true);
        }
        await dispatch(
            updateApplication({
                accessToken,
                id: params.id,
                state: filterstatus ? filterstatus[0].id : '',
            })
        );
        dispatch(createNotification({
            message: `Your Application ${e}`,
            user_id: user.user.id,
            accessToken: authToken,
            is_admin: false
        }))
        statusvalue();
    };

    // const closebutton = () => {
    //     setIsOpen(false)
    // }
    // const confirmcertification = (e) => {

    //     const filteredCategories = categories.filter((item) => item.name === applicationdata.form);

    //     const currentDate = new Date();
    //     const expirationDate = new Date(currentDate);

    //     if (selectedDuaration === '6month') {
    //         expirationDate.setMonth(expirationDate.getMonth() + 6);
    //     } else if (selectedDuaration === '1year') {
    //         expirationDate.setFullYear(expirationDate.getFullYear() + 1);
    //     }

    //     const postdata = {
    //         name: applicationdata.name,
    //         application_id: applicationdata.id,
    //         issued_at: currentDate.toISOString().split('T')[0],
    //         category_id: filteredCategories[0].id,
    //         issued_to: applicationdata.user_id,
    //         issued_by: user.user.id,
    //         expired_at: expirationDate.toISOString().split('T')[0],
    //     }
    //     const data = {
    //         accessToken: accessToken,
    //         postdata: postdata
    //     }
    //     dispatch(postCertificates(data))
    //     dispatch(createNotification({
    //         message: `Create Certification for ${selectedDuaration === '6month' ? '6 Month' : '1 Year '} `,
    //         user_id: user.user.id,
    //         accessToken: authToken,
    //         is_admin: false
    //     }))
    //     closebutton()
    // }


    useEffect(() => {
        statusvalue();
    }, [dispatch]);

    // useEffect(() => {
    //     localStorage.setItem('currentApp', JSON.stringify(currentApp));
    // }, [currentApp]);

    // const customStyles = {
    //     content: {
    //         top: '50%',
    //         left: '50%',
    //         right: 'auto',
    //         bottom: 'auto',
    //         marginRight: '-50%',
    //         transform: 'translate(-50%, -50%)',
    //     },
    // };


    return (
        <div>
            <Navbar />
            <Header />
            <div className="wrapper">
                <div className="boxAppBoard boxWhite">
                    <div className="AppFormBar noShadow">
                        <div className="title">
                            {currentApp?.form} / {currentApp?.name} / {formData.find((el) => el.id === currentPage)?.pageName}
                        </div>
                    </div>

                    <div className="boardHeader">
                        <div className="boardHeadLeft">
                            <h2>In Process Applications</h2>
                        </div>
                        <div className="boardHeadRight">
                            <div>
                                <select
                                    className="form-select"
                                    value={applicationdata?.state || 'All'}
                                    onChange={(e) => {
                                        selecteddata(e.target.value);
                                    }}
                                >
                                    <option disabled value="All">
                                        Select State
                                    </option>
                                    {status.length > 0 &&
                                        status.map((item) => (
                                            <option key={item.name} value={item.name}>
                                                {item.name === 'in_process' ? 'In Process' :
                                                    item.name === 'pending_from_dw' ? 'Pending from DW' :
                                                        item.name === 'discrepancies' ? 'Discrepancies' :
                                                            item.name === 'approved' ? 'Approved' :
                                                                item.name === 'renewal' ? 'Renewal' :
                                                                    'Unknown'}
                                            </option>
                                        ))
                                    }
                                </select>

                            </div>
                        </div>
                    </div>
                    <div className={classes['board-content']}>
                        {/* <form>
                            {
                                (!!currentApp?.json_data && currentApp?.json_data?.length > 0) &&
                                <div className='row mb-4'>
                                    <div className='col-6'>
                                        <label className='mb-2 d-block'>Discrepancy</label>
                                        <div class="btn-group align-items-center w-100">
                                            <input class="form-control rounded-0" type="text" value={discrepancy} onChange={(e) => setDiscrepancy(e.target.value)} />
                                            <button type='button' onClick={() => handleUpdate()} class="btn btn-primary rounded-0">Update</button>
                                        </div>
                                    </div>
                                </div>
                            }




                            <Modal
                                isOpen={modalIsOpen}
                                onRequestClose={closebutton}
                                contentLabel="Example Modal"
                            >
                                <div style={{ position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', backgroundColor: 'rgba(0, 0, 0, 0.5)', zIndex: 999 }}>
                                    <div style={{ backgroundColor: '#fff', width: '50%', margin: 'auto', marginTop: '10%', padding: '20px', borderRadius: '5px' }}>
                                        <h1 style={{ marginBottom: '20px' }}>Do you want certification?</h1>
                                        <div className="boardHeadRight">
                                            <div>
                                                <select
                                                    className="form-select"
                                                    style={{ width: '100%' }}
                                                    value={selectedDuaration}
                                                    onChange={(e) => { setselectedDuaration(e.target.value) }}
                                                >
                                                    <option selected value="All">Select Duaration</option>
                                                    <option key='1' value="6month">6 Month</option>
                                                    <option key='2' value="1year">1 Year</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div style={{ textAlign: 'right', marginTop: '20px' }}>
                                            <button style={{ marginRight: '10px', padding: '5px 10px', backgroundColor: '#ccc', border: 'none', borderRadius: '3px', cursor: 'pointer' }} onClick={(e) => { closebutton(e) }}>Close</button>
                                            <button onClick={(e) => { confirmcertification(e) }} style={{ padding: '5px 10px', backgroundColor: '#007bff', color: '#fff', border: 'none', borderRadius: '3px', cursor: 'pointer' }}>Confirm</button>
                                        </div>
                                    </div>
                                </div>
                            </Modal>

                            {formData?.map((page, pageIndex) => (
                                <>
                                    {page?.id === currentPage && (
                                        <div key={pageIndex} className={classes['page']}>
                                            {page?.sections?.map((section, sectionIndex) => (
                                                <>
                                                    {section.type === 'Divider' ? (
                                                        <div className="px-3">
                                                            <hr />
                                                        </div>
                                                    ) : (
                                                        <div style={{ border: 'solid 1px #ccc', padding: 15, margin: '40px 15px 15px 15px' }}>
                                                            <p className={classes['section-heading']}>{section?.heading}</p>
                                                            <div key={sectionIndex} style={{ display: 'flex' }}>
                                                                {section?.column?.map((col, columnIndex) => (
                                                                    <div key={columnIndex} style={{ flex: 1, marginRight: 10 }}>
                                                                        {col?.row?.map((rw, rowIndex) => (
                                                                            <div className="mb-4" key={rowIndex} style={{ width: '100%', padding: '0 5px', marginBottom: 15 }}>
                                                                                {rw?.content?.map((cont, contentIndex) => {
                                                                                    const uniqueKey = `${pageIndex}_${sectionIndex}_${columnIndex}_${rowIndex}_${contentIndex}`;
                                                                                    return (
                                                                                        <div key={contentIndex} style={{ width: '100%' }}>
                                                                                            <FormContent
                                                                                                pageIndex={pageIndex}
                                                                                                sectionIndex={sectionIndex}
                                                                                                columnIndex={columnIndex}
                                                                                                rowIndex={rowIndex}
                                                                                                contentIndex={contentIndex}
                                                                                                element={cont}
                                                                                                uniqueKey={uniqueKey}
                                                                                                activeDiscrepancy={activeDiscrepancy}
                                                                                                handleDiscrepancy={(ele) => setActiveDiscrepancy(ele)}
                                                                                            />
                                                                                        </div>
                                                                                    );
                                                                                })}
                                                                            </div>
                                                                        ))}
                                                                    </div>
                                                                ))}
                                                            </div>
                                                        </div>
                                                    )}
                                                </>
                                            ))}
                                        </div>
                                    )}
                                </>
                            ))}
                        </form> */}
                    </div>
                </div>
            </div>





        </div>
    );
};

export default ViewApp;
