import React from 'react'
import iconFolder from "../../../assets/media/icon-folder.svg";
import iconMenuDots from "../../../assets/media/icon-menu-dots.svg";
import iconUpload from "../../../assets/media/icon-upload.svg";
import iconUpload2 from "../../../assets/media/icon-upload-white.svg";
import imageUploaded from "../../../assets/media/image-uploaded.png";
import { Link } from 'react-router-dom';
import Navbar from '../../navbar';
import Header from '../../header';

const FileCabinet = () => {
    return (
        <div>
            <Navbar />
            <Header />
            <div className='wrapper'>
                <div className='boxAppBoard boxWhite'>
                    <div className='boardHeader noMg'>
                        <div className='boardHeadLeft'>
                            <h2>File Cabinet</h2>
                        </div>
                        <div className='boardHeadRight'>
                            <div className='boardFilter'>
                                <div className="form-check form-check-inline">
                                    <input className="form-check-input" type="checkbox" value="All" checked />
                                    <label className="form-check-label">All</label>
                                </div>
                                <div className="form-check form-check-inline">
                                    <input className="form-check-input" type="checkbox" value="Folders" />
                                    <label className="form-check-label">Folders</label>
                                </div>
                                <div className="form-check form-check-inline">
                                    <input className="form-check-input" type="checkbox" value="Attachments" />
                                    <label className="form-check-label">Attachments</label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='divFiles'>
                    <div className='headFiles'>
                        <div className='leftFiles'>
                            <h3 className='noMg'>Folders</h3>
                        </div>
                        <div className='rightFiles'>
                            <Link className='addButton' to={'/gaming-licence'}>
                                <img src={iconUpload2} alt="" />
                                <span>Create Folder</span>
                            </Link>
                        </div>
                    </div>
                    <div className='rowFolders'>
                        <div className='colFolder'>
                            <div className='foldWrap'>
                                <Link className='foldNav' to={'/gaming-licence'}><img src={iconMenuDots} alt='' /></Link>
                                <Link className='foldLink' to={'/gaming-licence'}><img src={iconFolder} alt="" /></Link>
                                <h6><Link to={'/gaming-licence'}>Tax Files</Link></h6>
                                <p>May 20, 2022</p>
                            </div>
                        </div>
                        <div className='colFolder'>
                            <div className='foldWrap'>
                                <Link className='foldNav' to={'/'}><img src={iconMenuDots} alt='' /></Link>
                                <Link className='foldLink' to={'/gaming-licence'}><img src={iconFolder} alt="" /></Link>
                                <h6><Link to={'/gaming-licence'}>Gaming Licence</Link></h6>
                                <p>May 20, 2022</p>
                            </div>
                        </div>
                        <div className='colFolder'>
                            <div className='foldWrap'>
                                <Link className='foldNav' to={'/'}><img src={iconMenuDots} alt='' /></Link>
                                <Link className='foldLink' to={'/gaming-licence'}><img src={iconFolder} alt="" /></Link>
                                <h6><Link to={'/gaming-licence'}>Proxy Gaming</Link></h6>
                                <p>May 20, 2022</p>
                            </div>
                        </div>
                        <div className='colFolder'>
                            <div className='foldWrap'>
                                <Link className='foldNav' to={'/'}><img src={iconMenuDots} alt='' /></Link>
                                <Link className='foldLink' to={'/gaming-licence'}><img src={iconFolder} alt="" /></Link>
                                <h6><Link to={'/gaming-licence'}>Tax Returns</Link></h6>
                                <p>May 20, 2022</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='divFiles'>
                    <div className='headFiles'>
                        <div className='leftFiles'>
                            <h3 className='noMg'>Attachments</h3>
                        </div>
                        <div className='rightFiles'>
                        </div>
                    </div>
                    <div className='rowAttachments'>
                        <div className='colAttachment upload'>
                            <img src={iconUpload} alt="" />
                            <span>Upload File</span>
                        </div>
                        <div className='colAttachment uploaded'>
                            <img src={imageUploaded} alt="" />
                        </div>
                        <div className='colAttachment uploaded'>
                            <img src={imageUploaded} alt="" />
                        </div>
                        <div className='colAttachment uploaded'>
                            <img src={imageUploaded} alt="" />
                        </div>
                        <div className='colAttachment uploaded'>
                            <img src={imageUploaded} alt="" />
                        </div>
                        <div className='colAttachment uploaded'>
                            <img src={imageUploaded} alt="" />
                        </div>
                        <div className='colAttachment uploaded'>
                            <img src={imageUploaded} alt="" />
                        </div>
                        <div className='colAttachment uploaded'>
                            <img src={imageUploaded} alt="" />
                        </div>
                        <div className='colAttachment uploaded'>
                            <img src={imageUploaded} alt="" />
                        </div>
                        <div className='colAttachment uploaded'>
                            <img src={imageUploaded} alt="" />
                        </div>
                        <div className='colAttachment uploaded'>
                            <img src={imageUploaded} alt="" />
                        </div>
                        <div className='colAttachment uploaded'>
                            <img src={imageUploaded} alt="" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default FileCabinet