// reducers/categoriesSlice.js
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { showError, showSuccess } from "../../helpers/toast";
import axios from "axios";

const API = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_URL,
});
API.interceptors.response.use(
  (response) => {
    return Promise.resolve(response);
  },
  (error) => {
    showError(error.response?.data?.error ?? error.message);
    return Promise.reject(error);
  }
);

const initialState = {
  fetchLoading: false,
  fetchError: null,
  message: null,
  status: [],
};

export const getstate = createAsyncThunk(
  "status/getstate",
  async (accessToken) => {
    try {
      const config = {
        headers: {
          Authorization: `${accessToken}`,
          "Content-Type": "application/json",
        },
      };

      const response = await API.get(`/states/`, config);
      return response.data;
    } catch (error) {
      throw new Error("Failed to get state details");
    }
  }
);

const stateSlice = createSlice({
  name: "status",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getstate.pending, (state) => {
        state.fetchLoading = true;
      })
      .addCase(getstate.fulfilled, (state, action) => {
        state.fetchLoading = false;
        state.status = action.payload;
        state.fetchError = null;
      })
      .addCase(getstate.rejected, (state, action) => {
        state.fetchLoading = false;
        state.fetchError = action.error.message;
        state.status = null;
        state.message = null;
      });
  },
});

export default stateSlice.reducer;
