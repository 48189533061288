import React, { Fragment, useEffect, useState } from 'react'
import { useFormik } from 'formik';
import * as Yup from 'yup';
import iconFilePdf from "../../../assets/media/icon-file-pdf.svg";
import iconFileXls from "../../../assets/media/icon-file-xls.svg";
import iconFileImage from "../../../assets/media/icon-file-image.svg";
import iconFileDoc from "../../../assets/media/icon-file-doc.svg";
import iconFileEdit from "../../../assets/media/icon-file-edit.svg";
import iconFileDelete from "../../../assets/media/icon-file-delete.svg";
import iconFileDownload from "../../../assets/media/icon-file-download.svg";
import iconRenewel2 from "../../../assets/media/icon-renewel-2.svg";
import iconTimeSquare from "../../../assets/media/icon-time-square.svg";
import iconSwap from "../../../assets/media/icon-swap.svg";
import iconTick from "../../../assets/media/icon-tick.svg";
import iconSearch from "../../../assets/media/icon-search.svg";
import iconRenewel3 from "../../../assets/media/icon-renewel-3.svg";
import iconCaretLeft from "../../../assets/media/icon-caret-left.svg";
import iconCaretRight from "../../../assets/media/icon-caret-right.svg";
import iconCaretDown from "../../../assets/media/icon-caret-down.svg";
import iconStateCompany from "../../../assets/media/icon-state-company.svg";
import iconStateEmployee from "../../../assets/media/icon-state-employee.svg";
import iconStateApplication from "../../../assets/media/icon-state-application.svg";


import { Link } from 'react-router-dom';
import Navbar from '../../navbar';
import Header from '../../header';
import { useDispatch, useSelector } from 'react-redux';
import { fetchApplications } from '../../../store/reducers/applicationSlice';
import Pagination from './appPagination';
import Modal from '../../modal/Modal';
//import { AreaChartWithFilters, Filter } from './chartComponents';

import { PureComponent } from 'react';
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import { getallUser } from '../../../store/reducers/userSlice';
import { fetchEmployee } from '../../../store/reducers/employeeSlice';
import { fetchForms } from '../../../store/reducers/formSlice';
import { fetchCompanies } from '../../../store/reducers/companiesSlice';


export const formatDate = (inputDate) => {
    const date = new Date(inputDate);
    const options = { year: "numeric", month: "long", day: "2-digit" };
    return date.toLocaleDateString(undefined, options);
};

function CheckboxFilter({ checkboxes, setCheckboxes, setSearchTerm }) {
    const handleCheckboxChange = (event) => {
        const { value, checked } = event.target;
        if (value === 'All') {
            setCheckboxes((prevCheckboxes) => {
                const newCheckboxes = { ...prevCheckboxes };
                for (const key in newCheckboxes) {
                    newCheckboxes[key] = checked;
                }
                return newCheckboxes;
            });
            setSearchTerm("")
        } else {
            setCheckboxes((prevCheckboxes) => ({
                ...prevCheckboxes,
                [value]: checked
            }));
            setSearchTerm("")
        }
    };

    return (
        <div className='boardFilter'>
            {Object.keys(checkboxes).map((checkboxValue) => (
                <div className='form-check form-check-inline' key={checkboxValue}>
                    <input
                        className='form-check-input'
                        type='checkbox'
                        value={checkboxValue}
                        checked={checkboxes[checkboxValue]}
                        onChange={handleCheckboxChange}
                    />
                    <label className='form-check-label'>{checkboxValue}</label>
                </div>
            ))}
        </div>
    );
}
const stateButtonMap = {
    in_process: { className: 'btnProcess', text: 'In Process', state: 'in_process' },
    pending: { className: 'btnPending', text: 'Pending', state: 'pending' },
    approved: { className: 'btnApproved', text: 'Approved', state: 'approved' },
    rejected: { className: 'btnRejected', text: 'Rejected', state: 'rejected' },
    discrepancies: { className: 'btnDiscrepancies', text: 'Discrepancies', state: 'discrepancies' },
    pending_from_dw: { className: 'btnPendingFromDW', text: 'Pending From DW', state: 'pending_from_dw' },
    drafts: { className: 'btnDrafts', text: 'Drafts', state: 'drafts' },
    renewal: { className: 'btnRenewal', text: 'Renewal', state: 'renewal' }
};

const AppDashboard = () => {
    const dispatch = useDispatch();
    const user = useSelector((state) => state.auth.user);
    const [data, setData] = useState([]);
    const [searchTerm, setSearchTerm] = useState("")
    const getalluserdata = useSelector((state) => state.usersdata.getalluserdata);
    const employee = useSelector((state) => state.employee.users);
    const forms = useSelector((state) => state.forms.data);
    const company = useSelector((state) => state.companies.companies)
    const [selectedYear, setSelectedYear] = useState('All');
    const [selectedCompany, setselectedCompany] = useState('All');

    const [showModal, setShowModal] = useState(false);

    const dataUsers = [
        { name: 'Jan', Users: 0 },
        { name: 'Feb', Users: 0 },
        { name: 'Mar', Users: 0 },
        { name: 'Apr', Users: 0 },
        { name: 'May', Users: 0 },
        { name: 'Jun', Users: 0 },
        { name: 'Jul', Users: 0 },
        { name: 'Aug', Users: 0 },
        { name: 'Sep', Users: 0 },
        { name: 'Oct', Users: 0 },
        { name: 'Nov', Users: 0 },
        { name: 'Dec', Users: 0 }
    ];

    const dataApplications = [
        { name: 'Jan', Applications: 0 },
        { name: 'Feb', Applications: 0 },
        { name: 'Mar', Applications: 0 },
        { name: 'Apr', Applications: 0 },
        { name: 'May', Applications: 0 },
        { name: 'Jun', Applications: 0 },
        { name: 'Jul', Applications: 0 },
        { name: 'Aug', Applications: 0 },
        { name: 'Sep', Applications: 0 },
        { name: 'Oct', Applications: 0 },
        { name: 'Nov', Applications: 0 },
        { name: 'Dec', Applications: 0 }
    ];

    const dataEmployees = [
        { name: 'Jan', Employees: 0 },
        { name: 'Feb', Employees: 0 },
        { name: 'Mar', Employees: 0 },
        { name: 'Apr', Employees: 0 },
        { name: 'May', Employees: 0 },
        { name: 'Jun', Employees: 0 },
        { name: 'Jul', Employees: 0 },
        { name: 'Aug', Employees: 0 },
        { name: 'Sep', Employees: 0 },
        { name: 'Oct', Employees: 0 },
        { name: 'Nov', Employees: 0 },
        { name: 'Dec', Employees: 0 }
    ];

    const authToken = user.access_token;
    const initialState = {
        'All': true,
        // 'Pending': false,
        'Approved': false,
        'Rejected': false,
        'Pending From DW': false,
        'In Process': false,
        // Discrepancies: false,
        // Drafts: false,
        // Renewal: false,
    };

    // useEffect(() => {
    //     dispatch(fetchApplications({ accessToken: authToken }));
    // }, []);
    const {
        handleChange,
        handleBlur,
        handleSubmit,
        values,
        touched,
        errors,
    } = useFormik({
        initialValues: {
            category: '',
            'application-name': '',
            'application-type': ''
        },
        validationSchema: Yup.object({
            category: Yup.string().required('Category is required'),
            'application-name': Yup.string().required('Application Name is required'),
            'application-type': Yup.string().required('Application Type is required'),
        }),
        onSubmit: (formValues) => {
            console.log({ formValues: formValues })
            // dispatch(loginUser({ emailOrPhone: formValues.email, password: formValues.password }));
        },
    });

    const [currentPage, setCurrentPage] = useState(1);
    const [checkboxes, setCheckboxes] = useState(initialState);
    const applicationRecords = useSelector(state => state.applications.data)
    const applicationLoading = useSelector(state => state.applications.loading)
    const userLoading = useSelector(state => state.auth.loading)
    const filtered = (search = "") => {
        const filteredData = applicationRecords.filter((item) => {
            let stateInfo = stateButtonMap[item.state];
            return (
                (stateInfo && checkboxes[stateInfo.text]) || checkboxes['All'] === true ||
                typeof stateInfo === 'undefined'
            );
        }).map((item) => ({
            ...item,
            created_at: formatDate(item.created_at),
            updated_at: formatDate(item.updated_at),
            stateText: stateButtonMap[item.state].text
        })).filter((item) => {
            for (const key in item) {
                if (typeof item[key] === 'string' && item[key].toLowerCase().includes(search.toLowerCase())) {
                    return true;
                }
            }
            return false;
        });
        
        setData(filteredData);
        return filteredData;
    };
    
 
  
    getalluserdata.forEach(user => {
        const year = new Date(user.created_at).getFullYear();
        if (selectedYear === 'All' || year.toString() === selectedYear) {
            const name = new Date(user.created_at).getMonth();
            dataUsers[name].Users++;
        }
    });
    
    applicationRecords.forEach(application => {
        const year = new Date(application.created_at).getFullYear();
        if (selectedYear === 'All' || year.toString() === selectedYear) {
            const name = new Date(application.created_at).getMonth();
            dataApplications[name].Applications++;
        }
    });
    
    employee.forEach(Employee => {
        const year = new Date(Employee.created_at).getFullYear();
        if (selectedYear === 'All' || year.toString() === selectedYear) {
            const name = new Date(Employee.created_at).getMonth();
            dataEmployees[name].Employees++;
        }
    });

    
   
    const filter_company_employee = employee.filter(Employee => {
        if (selectedCompany === 'All') {
            return Employee.company_id != null;
        } else {
            return Employee.company_id === Number(selectedCompany);
        }
    });
    
    
    const filter_company_application = applicationRecords.filter(application => {
        return filter_company_employee.some(employee => {
            if (selectedCompany === 'All') {
                return application.user_id != null;
            } else {
                console.log(employee.id)
                return application.user_id === Number(employee.id);
            }
        });
    });

    const filter_company = company.filter(item => {
        if (selectedCompany === 'All') {
            return item;
        } else {
            return item.id === Number(selectedCompany);
        }
    });

    useEffect(() => {
        dispatch(getallUser(authToken));
        dispatch(fetchEmployee({ accessToken: authToken }));
        dispatch(fetchForms({ accessToken: authToken }));
        dispatch(fetchCompanies(authToken));
    }, [authToken, dispatch]);


    const handleInputChange = event => {
        filtered(event.target.value);
        setSearchTerm(event.target.value)
    };

    useEffect(() => setData(filtered()), [applicationRecords, checkboxes])

    const accessToken = useSelector((state) => state.auth.user.access_token)

    useEffect(() => {
        dispatch(fetchApplications({ accessToken: accessToken }))
    }, [])
    const getTotalPages = () => {
        if (Number.isInteger(data?.length / 10)) return data?.length / 10;
        return Math.floor(data?.length / 10) + 1
    }
    useEffect(() => console.log({ pages: getTotalPages() }), [])

    const getApplicationCount = () => {
        let newApplications = 0, pendingFromDW = 0, discrepencies = 0, approved = 0, renewal = 0;
        applicationRecords?.map((item) => {
            if (item.state === "in_process") newApplications++
            else if (item.state === "pending_from_dw") pendingFromDW++
            else if (item.state === "discrepancies") discrepencies++
            else if (item.state === "approved") approved++
            else if (item.state === "renewal") renewal++
        })
        return { newApplications, pendingFromDW, discrepencies, approved, renewal }
    }


    if (applicationLoading || userLoading) {
        return <p>loading</p>
    }

    const show = () => setShowModal(true)
    const close = () => setShowModal(false)



    return (
        <>
            <div>
                <Navbar />
                <Header />
                <div className='wrapper'>
                    <div className=''>
                        <div className=''>
                            <div className='profHeader mb-4'>
                                <div className='profHeadLeft'>
                                    <h2>Application Forms</h2>
                                </div>
                                <div className='profHeadRight'>
                                    <a className='buttonPrimary' onClick={show}>Create Form</a>
                                </div>
                            </div>
                            <div className='d-flex mb-4'>
                                <div className='appState stateProcess w-100'>
                                    <h3>
                                        <img src={iconRenewel2} alt='' />
                                        <span>{getApplicationCount().newApplications}</span>
                                    </h3>
                                    <h4>New Application</h4>
                                    <Link to={'/in-process-applications'}>View All</Link>
                                </div>
                                <div className='appState statePending w-100'>
                                    <h3>
                                        <img src={iconTimeSquare} alt='' />
                                        <span>{getApplicationCount().pendingFromDW}</span>
                                    </h3>
                                    <h4>Pending From DW</h4>
                                    <Link to={'/pending-applications'}>View All</Link>
                                </div>
                                <div className='appState stateDiscrepancies w-100'>
                                    <h3>
                                        <img src={iconSwap} alt='' />
                                        <span>{getApplicationCount().discrepencies}</span>
                                    </h3>
                                    <h4>Discrepancies</h4>
                                    <Link to={'/discrepancies'}>View All</Link>
                                </div>
                                <div className='appState stateApproved w-100'>
                                    <h3>
                                        <img src={iconTick} alt='' />
                                        <span>{getApplicationCount().approved}</span>
                                    </h3>
                                    <h4>Approved</h4>
                                    <Link to={'/approved-applications'}>View All</Link>
                                </div>
                                <div className='appState stateRenewel w-100 m-0'>
                                    <h3>
                                        <img src={iconTick} alt='' />
                                        <span>{getApplicationCount().renewal}</span>
                                    </h3>
                                    <h4>Renewal</h4>
                                    <Link to={'/renewals'}>View All</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-12 col-lg-8'>
                            <div className='boxWhite boxChart'>
                                <div className='rowChartHead'>
                                    <div className='colChartHead'>
                                        <h3><b>{forms.length}</b><span>Submitted Forms</span></h3>
                                    </div>
                                    <div className='colChartHead'>
                                        <ul className="nav nav-tabs" id="myTab">
                                            <li className="nav-item">
                                                <button className="nav-link active" data-bs-toggle="tab" data-bs-target="#dataUsers" type="button">User Registration</button>
                                            </li>
                                            <li className="nav-item">
                                                <button className="nav-link" data-bs-toggle="tab" data-bs-target="#dataApplications" type="button">Applications</button>
                                            </li>
                                            <li className="nav-item">
                                                <button className="nav-link" data-bs-toggle="tab" data-bs-target="#dataEmployees" type="button">No. of Employees</button>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className='colChartHead'>
                                        <select className="form-select" onChange={(e) => setSelectedYear(e.target.value)}>
                                            <option selected value="All">Select Year</option>
                                            {[...Array(10)].map((_, index) => {
                                                const year = new Date().getFullYear() - index;
                                                return <option key={year} value={year}>{year}</option>;
                                            })}
                                        </select>
                                    </div>
                                </div>
                                <div className="tab-content">
                                    <div className="tab-pane active" id="dataUsers">
                                        <div className='dataUsers' >
                                            <AreaChart
                                                width={750}
                                                height={225}
                                                data={dataUsers}
                                                margin={{
                                                    top: 0,
                                                    right: 20,
                                                    left: 0,
                                                    bottom: 0,
                                                }}
                                            >
                                                <CartesianGrid strokeDasharray="3 3" />
                                                <XAxis dataKey="name" />
                                                <YAxis allowDecimals={false} />
                                                <Tooltip />
                                                <Area type="monotone" dataKey="Users" stroke="#114DF2" fill="#F0F5FF" />
                                            </AreaChart>
                                        </div>
                                    </div>
                                    <div className="tab-pane" id="dataApplications">
                                        <div className='dataApplications' >
                                            <AreaChart
                                                width={750}
                                                height={225}
                                                data={dataApplications}
                                                margin={{
                                                    top: 0,
                                                    right: 20,
                                                    left: 0,
                                                    bottom: 0,
                                                }}
                                            >
                                                <CartesianGrid strokeDasharray="3 3" />
                                                <XAxis dataKey="name" />
                                                <YAxis allowDecimals={false} />
                                                <Tooltip />
                                                <Area type="monotone" dataKey="Applications" stroke="#114DF2" fill="#F0F5FF" />
                                            </AreaChart>
                                        </div>
                                    </div>
                                    <div className="tab-pane" id="dataEmployees">
                                        <div className='dataEmployees' >
                                            <AreaChart
                                                width={750}
                                                height={225}
                                                data={dataEmployees}
                                                margin={{
                                                    top: 0,
                                                    right: 20,
                                                    left: 0,
                                                    bottom: 0,
                                                }}
                                            >
                                                <CartesianGrid strokeDasharray="3 3" />
                                                <XAxis dataKey="name" />
                                                <YAxis allowDecimals={false} />
                                                <Tooltip />
                                                <Area type="monotone" dataKey="Employees" stroke="#114DF2" fill="#F0F5FF" />
                                            </AreaChart>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-12 col-lg-4'>
                            <div className='formStates boxWhite'>
                            company
                                <select className="form-select" onChange={(e)=>setselectedCompany(e.target.value)}>
                                    <option selected value="All">All Companies</option>
                                    {company ? 
                                    company.map((item,index)=>{
                                        return(
                                            <option value={item.id} key={index}>{item.company_name}</option>
                                        )
                                    })
                                    :''
                                    
                                }
                                   
                                </select>
                                <div className='rowCompanyStates'>
                                    <div className='colCompanyStates'>
                                        <div className='infoCompanyStates'>
                                            <img src={iconStateCompany} alt='' />
                                            <span>All Operators</span>
                                        </div>
                                        <div className='numCompanyStates'>
                                            <span>{filter_company.length}</span>
                                        </div>
                                    </div>
                                    <div className='colCompanyStates'>
                                        <div className='infoCompanyStates'>
                                            <img src={iconStateEmployee} alt='' />
                                            <span>Employees</span>
                                        </div>
                                        <div className='numCompanyStates'>
                                            <span>{filter_company_employee.length}</span>
                                        </div>
                                    </div>
                                    <div className='colCompanyStates'>
                                        <div className='infoCompanyStates'>
                                            <img src={iconStateApplication} alt='' />
                                            <span>Applications</span>
                                        </div>
                                        <div className='numCompanyStates'>
                                            <span>{filter_company_application.length}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='boxAppBoard boxWhite'>
                        <div className='boardHeader'>
                            <CheckboxFilter setSearchTerm={setSearchTerm} checkboxes={checkboxes} setCheckboxes={setCheckboxes} />
                            <div className='boardHeadRight'>
                                <div className='searchForm'>
                                    <input type='text' placeholder='input search text' onChange={handleInputChange} value={searchTerm} />
                                    <button><img src={iconSearch} alt='' /></button>
                                </div>
                                <div className='datePicker'>
                                </div>
                            </div>
                        </div>
                        <div className='boardContent'>

                            <div className='boardThead'>
                                <div className='boardCol w-85'>
                                    S.NO.
                                </div>
                                <div className='boardCol w-165'>
                                    Application No.
                                </div>
                                <div className='boardCol w-adj1'>
                                    Application Name
                                </div>
                                <div className='boardCol w-165'>
                                    Submitted Date
                                </div>
                                <div className='boardCol w-165'>
                                    Registered Date
                                </div>
                                <div className='boardCol w-165'>
                                    Status
                                </div>
                                <div className='boardCol w-165'>
                                    Attachments
                                </div>
                                <div className='boardCol w-110'>

                                </div>
                            </div>
                            {data?.map((application, index) => {
                                let stateInfo = stateButtonMap[application.state];
                                return <Fragment key={index}>
                                    <div className={`boardTrow ${(currentPage === 1 ? currentPage : ((currentPage - 1) * 10) + 1) <= index + 1 && index < (currentPage === 1 ? 10 : (currentPage * 10)) ? '' : 'd-none'} `}>
                                        <div className='boardCol w-85'>
                                            {index + 1}
                                        </div>
                                        <div className='boardCol w-165'>
                                            GB-4939907
                                        </div>
                                        <div className='boardCol w-adj1'>
                                            <div className='boardTitle'>
                                                <strong>{application.name}</strong>
                                                <span>{application.form}</span>
                                            </div>
                                        </div>
                                        <div className='boardCol w-165'>
                                            {formatDate(application.updated_at)}
                                        </div>
                                        <div className='boardCol w-165'>
                                            {formatDate(application.created_at)}
                                        </div>
                                        <div className='boardCol w-165'> <Link className={`boardButton ${stateInfo.className}`} to={'/'}>
                                            {stateInfo.text}
                                        </Link></div>
                                        <div className='boardCol w-165'>
                                            <div className='boardDownload'>
                                                <Link to={'/'}><img src={iconFilePdf} alt='' /></Link>
                                                <Link to={'/'}><img src={iconFileDoc} alt='' /></Link>
                                                <Link to={'/'}><img src={iconFileImage} alt='' /></Link>
                                            </div>
                                        </div>
                                        <div className='boardCol w-110'>
                                            <div className='boardActions'>
                                                {
                                                    index % 2 === 0 ? <Link to={'/'}><img src={iconFileDelete} alt='' /></Link> :
                                                        <Link to={'/'}><img src={iconFileEdit} alt='' /></Link>
                                                }
                                                <Link to={'/'}><img src={iconFileDownload} alt='' /></Link>
                                            </div>
                                        </div>
                                    </div>
                                </Fragment>
                            })
                            }
                        </div>
                        <Pagination
                            currentPage={currentPage}
                            setCurrentPage={setCurrentPage}
                            totalPages={getTotalPages}
                        />
                    </div>
                </div>
            </div>
            <Modal show={showModal} colWidth={3}>
                <div className=''>
                    <p>Create Application Form</p>
                    <form onSubmit={handleSubmit}>
                        <div className='mb-3 d-flex flex-column'>
                            <label style={{
                                color: "var(--day-break-blue-10, #082A51)",
                                fontFamily: "Roboto",
                                fontSize: "14px",
                                fontStyle: "normal",
                                fontWeight: "400",
                                lineHeight: "22px",
                            }}>Category</label>
                            <input className='form-class' style={{
                                padding: 5,
                                borderRadius: "6px",
                                border: "1px solid var(--neutral-5, #D9D9D9)",
                                background: "var(--neutral-1, #FFF)",
                            }}
                                value={values['category']}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                autoComplete={"false"}
                                id={'category'}
                                name={"category"}
                            />
                            {touched['category'] && errors['category'] && <div className="error-message">{errors['category']}</div>}
                        </div>
                        <div className='mb-3 d-flex flex-column'>
                            <label style={{
                                color: "var(--day-break-blue-10, #082A51)",
                                fontFamily: "Roboto",
                                fontSize: "14px",
                                fontStyle: "normal",
                                fontWeight: "400",
                                lineHeight: "22px",
                            }}>Application Name</label>
                            <input className='form-class' style={{
                                padding: 5,
                                borderRadius: "6px",
                                border: "1px solid var(--neutral-5, #D9D9D9)",
                                background: "var(--neutral-1, #FFF)",
                            }}
                                value={values['application-name']}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                autoComplete={"false"}
                                id={'application-name'}
                                name={"application-name"}
                            />
                            {touched['application-name'] && errors['application-name'] && <div className="error-message">{errors['application-name']}</div>}
                        </div>
                        <div className='mb-3 d-flex flex-column'>
                            <label style={{
                                color: "var(--day-break-blue-10, #082A51)",
                                fontFamily: "Roboto",
                                fontSize: "14px",
                                fontStyle: "normal",
                                fontWeight: "400",
                                lineHeight: "22px",
                            }}>Application Type</label>
                            <input className='form-class' style={{
                                padding: 5,
                                borderRadius: "6px",
                                border: "1px solid var(--neutral-5, #D9D9D9)",
                                background: "var(--neutral-1, #FFF)",
                            }}
                                value={values['application-type']}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                autoComplete={"false"}
                                id={'application-type'}
                                name={"application-type"}
                            />
                            {touched['application-type'] && errors['application-type'] && <div className="error-message">{errors['application-type']}</div>}
                        </div>
                        <div className='d-flex justify-content-between'>
                            <div className=''>
                                <button type="submit" className='create-form-button'>Create</button>
                            </div>
                            <div className='gb-form-footer'>
                                <button type="button" onClick={close} className='create-form-cancel-button'>Cancel</button>
                            </div>
                        </div>
                    </form>
                </div>
            </Modal>
        </>
    )
}
export default AppDashboard