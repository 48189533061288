// reducers/applicationSlice.js
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { showError, showSuccess } from "../../helpers/toast";
import axios from "axios";

const API = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_URL,
});

API.interceptors.response.use(
  (response) => {
    return Promise.resolve(response);
  },
  (error) => {
    showError(error.response?.data?.error ?? error.message);
    return Promise.reject(error);
  }
);

const initialState = {
  fetchLoading: false,
  fetchError: null,
  message: null,
  data: [],
  currentApp: {},
};

export const updateApplication = createAsyncThunk(
  "updateApplication",
  async (data) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `${data.accessToken}`,
        },
      };

      const payload = {};
      if (data?.state) payload.state_id = data?.state;
      if (data?.json_data) payload.json_data = data?.json_data;

      const response = await API.put(
        `/applications/${data?.id}`,
        payload,
        config
      );
      showSuccess("Your application has been updated!");
      return response.data;
    } catch (error) {
      throw new Error("Failed to load applications");
    }
  }
);

export const fetchApplications = createAsyncThunk(
  "fetchApplications",
  async (data) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `${data.accessToken}`,
        },
      };
      const response = await API.get("/applications", config);
      return response.data;
    } catch (error) {
      throw new Error("Failed to load applications");
    }
  }
);
export const fetchApplicationWithId = createAsyncThunk(
  "fetchApplicationWithId",
  async (data) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `${data.accessToken}`,
        },
      };
      const response = await API.get(`/applications/${data?.id}`, config);
      return response.data;
    } catch (error) {
      throw new Error("Failed to load applications");
    }
  }
);
export const postForm = createAsyncThunk("postForms", async (data) => {
  try {
    const config = {
      headers: {
        "ngrok-skip-browser-warning": "69420",
        "Content-Type": "application/json",
        Authorization: `${data.accessToken}`,
      },
    };

    const response = await API.post("/forms", { ...data?.data }, config);
    showSuccess("Your application has been created!");
    return response.data;
  } catch (error) {
    throw new Error("Failed to submit form");
  }
});
export const updateForm = createAsyncThunk("updateForm", async (data, id) => {
  try {
    const config = {
      headers: {
        "ngrok-skip-browser-warning": "69420",
        "Content-Type": "application/json",
        Authorization: `${data.accessToken}`,
      },
    };
    const response = await API.put(
      `/forms/${data?.id}`,
      { ...data?.data },
      config
    );
    showSuccess("Your application has been updated!");

    return response.data;
  } catch (error) {
    throw new Error("Failed to submit form");
  }
});
export const verifyApplicationName = createAsyncThunk(
  "verifyApplicationName",
  async (data) => {
    const config = {
      headers: {
        "ngrok-skip-browser-warning": "69420",
        "Content-Type": "application/json",
        Authorization: `${data.accessToken}`,
      },
    };
    return API.post("/forms/verify-application-name", { ...data }, config);
  }
);

const applicationSlice = createSlice({
  name: "applications",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchApplications.pending, (state) => {
        state.fetchLoading = true;
      })
      .addCase(fetchApplications.fulfilled, (state, action) => {
        state.fetchLoading = false;
        state.data = action.payload;
        state.fetchError = null;
      })
      .addCase(fetchApplications.rejected, (state, action) => {
        state.fetchLoading = false;
        state.fetchError = action.error.message;
        state.data = null;
        state.message = null;
      })
      .addCase(fetchApplicationWithId.pending, (state) => {
        state.fetchLoading = true;
      })
      .addCase(fetchApplicationWithId.fulfilled, (state, action) => {
        state.fetchLoading = false;
        state.currentApp = action.payload;
        state.fetchError = null;
      })
      .addCase(fetchApplicationWithId.rejected, (state, action) => {
        state.fetchLoading = false;
        state.fetchError = action.error.message;
        state.currentApp = null;
        state.message = null;
      });
  },
});

export default applicationSlice.reducer;
