import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { showError, showSuccess } from "../../helpers/toast";

const API = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_URL,
});

API.interceptors.response.use(
  (response) => Promise.resolve(response),
  (error) => {
    // Display error message
    console.error(error.response?.data?.message || error.message);
    return Promise.reject(error);
  }
);

// Async thunk for creating a company
export const createCompany = createAsyncThunk(
  "companies/createCompany",
  async (data, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          Authorization: `${data.accessToken}`,
          "Content-Type": "application/json",
        },
      };
      const response = await API.post("/company/", data.companyData);
      showSuccess("Create Successfully");
      return response.data;
    } catch (error) {
      showError(error.response.data.error);
      return rejectWithValue(
        error.response?.data?.message || "Failed to create company"
      );
    }
  }
);

// Async thunk for updating a company
export const updateCompany = createAsyncThunk(
  "companies/updateCompany",
  async ({ companyId, companyData, accessToken }, { rejectWithValue }) => {
    console.log(companyId, companyData, "kkkkkkkkkkkkkkkkkkkkkkkkkkkk");
    try {
      const config = {
        headers: {
          Authorization: `${accessToken}`,
          "Content-Type": "application/json",
        },
      };
      const response = await API.put(
        `/company/${companyId}`,
        companyData,
        config
      );
      showSuccess("Update Successfully");
      return response.data;
    } catch (error) {
      return rejectWithValue(
        error.response?.data?.message || "Failed to update company"
      );
    }
  }
);

// Async thunk for deleting a company
export const deleteCompany = createAsyncThunk(
  "companies/deleteCompany",
  async ({ configdata }, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          Authorization: `${configdata.accessToken}`,
          "Content-Type": "application/json",
        },
      };
      const response = await API.delete(
        `/company/${configdata.company_id}`,
        config
      );
      showSuccess("Delete Successfully");
      return configdata.company_id;
    } catch (error) {
      return rejectWithValue(
        error.response?.data?.message || "Failed to delete company"
      );
    }
  }
);

// Async thunk for fetching companies
export const fetchCompanies = createAsyncThunk(
  "companies/fetchCompanies",
  async (accessToken, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          Authorization: `${accessToken}`,
          "Content-Type": "application/json",
        },
      };
      const response = await API.get("/company/", config);
      return response.data;
    } catch (error) {
      return rejectWithValue(
        error.response?.data?.message || "Failed to fetch companies"
      );
    }
  }
);

const initialState = {
  companies: [],
  loading: false,
  error: null,
};

const companiesSlice = createSlice({
  name: "companies",
  initialState,
  reducers: {},
  extraReducers: {
    [createCompany.pending]: (state) => {
      state.loading = true;
    },
    [createCompany.fulfilled]: (state, action) => {
      state.loading = false;
      state.companies.push(action.payload);
      state.error = null;
    },
    [createCompany.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    [updateCompany.fulfilled]: (state, action) => {
      state.loading = false;
      const index = state.companies.findIndex(
        (company) => company.id === action.payload.id
      );
      if (index !== -1) {
        state.companies[index] = action.payload;
      }
      state.error = null;
    },
    [deleteCompany.fulfilled]: (state, action) => {
      state.loading = false;
      state.companies = state.companies.filter(
        (company) => company.id !== action.payload
      );
      state.error = null;
    },
    [fetchCompanies.pending]: (state) => {
      state.loading = true;
    },
    [fetchCompanies.fulfilled]: (state, action) => {
      state.loading = false;
      state.companies = action.payload;
      state.error = null;
    },
    [fetchCompanies.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export default companiesSlice.reducer;
