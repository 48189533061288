import { configureStore } from '@reduxjs/toolkit';
import rootReducer from './reducers';
import { persistStore } from "redux-persist";

const persistedStateJSON = localStorage.getItem('reduxState'); // Change 'localStorage' to 'sessionStorage' if you prefer session storage
const persistedState = persistedStateJSON ? JSON.parse(persistedStateJSON) : {};

const store = configureStore({
  reducer: rootReducer,
  preloadedState: persistedState,
});

store.subscribe(() => {
  store.getState();
});

const persistor = persistStore(store);

export { store, persistor };
