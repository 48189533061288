import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import iconFileDelete from "../../../assets/media/icon-file-delete.svg";
import iconSearch from "../../../assets/media/icon-search.svg";
import iconCaretLeft from "../../../assets/media/icon-caret-left.svg";
import iconCaretRight from "../../../assets/media/icon-caret-right.svg";
import iconCaretDown from "../../../assets/media/icon-caret-down.svg";
import Avatar01 from "../../../assets/media/avatar-01.png";

import Navbar from '../../navbar';
import Header from '../../header';

import { useDispatch, useSelector } from 'react-redux';
import { addEmployee } from '../../../store/reducers/employeeSlice.js';
import { fetchEmployee } from '../../../store/reducers/employeeSlice.js';
import { fetchFeedbacks } from '../../../store/reducers/feedbackSlice';
import Pagination from '../dashboard/appPagination';
import Modal from '../../modal/Modal';
import classes from "./style.module.css"
import { fetchCompanies } from '../../../store/reducers/companiesSlice.js';
import userImagePlaceholder from "../../../assets/media/avatar2.jpg";

const AppFeedback = () => {
    const dispatch = useDispatch();
    const feedbacks = useSelector((state) => state.feedbacks.data);
    const companies = useSelector((state) => state.companies.companies);
    const fetchLoading = useSelector((state) => state.employee.fetchLoading);
    const [searchTerm, setSearchTerm] = useState("");
    const [filteredFeedbacks, setFilteredFeedbacks] = useState([]);
    const [filteredByCompany, setFilteredByCompany] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [showModal, setShowModal] = useState(false);
    const [modalContent, setModalContent] = useState({});
    const user = useSelector((state) => state.auth.user);
    const [selectedCompanyId, setSelectedCompanyId] = useState("");

    useEffect(() => {
        dispatch(fetchFeedbacks({ accessToken: user.access_token }));
        dispatch(fetchCompanies(user.access_token));
    }, [dispatch, user]);

    useEffect(() => {
        filterByCompany(selectedCompanyId); // Apply company filter initially
    }, [feedbacks, selectedCompanyId]);

    useEffect(() => {
        const filteredData = feedbacks.filter((item) =>
            Object.values(item).some((value) =>
                item.toString().toLowerCase().includes(searchTerm.toLowerCase())
            )
        ).sort((a, b) => b.id - a.id);
        setFilteredFeedbacks(filteredData);
    }, [searchTerm, feedbacks]);

    const handleInputChange = event => {
        setSearchTerm(event.target.value);
    };

    const filterByCompany = (companyId) => {
        if (companyId) {
            const filteredData = feedbacks.filter((item) => item.company_id === companyId);
            setFilteredByCompany(filteredData);
        } else {
            setFilteredByCompany(feedbacks);
        }
    };

     // State to store the selected company_id

    const handleCompanyFilter = (companyId) => {
        setSelectedCompanyId(companyId);
        filterByCompany(companyId);
    };

    const getTotalPages = () => {
        if (Number.isInteger(filteredFeedbacks?.length / 10)) return filteredFeedbacks?.length / 10;
        return Math.floor(filteredFeedbacks?.length / 10) + 1
    }
   


   
    const show = (filteredFeedbacks) => {
        console.log({ filteredFeedbacks })
        setModalContent(filteredFeedbacks)
        setShowModal(true)
    }
    const close = () => {
        setModalContent({})
        setShowModal(false)
    }

 

    return (
        <>
            <div>
                <Navbar />
                <Header />
                <div className='wrapper'>
                    <div className='boxAppBoard boxWhite'>
                        <div className='boardHeader noMg'>
                            <div className='boardHeadLeft'>
                                <h2>Feedbacks</h2>
                            </div>
                        </div>
                    </div>

                    <div className='boxAppBoard boxWhite mt-5'>
                        <div className='boardHeader'>
                            <div className='boardHeadLeft'>
                                <h2>All Feedbacks</h2>
                            </div>
                            <div className='boardHeadRight'>
                                <div className='searchForm'>
                                    <input type='text' placeholder='input search text' onChange={handleInputChange} value={searchTerm} />
                                    <button><img src={iconSearch} alt='' /></button>
                                </div>
                                <div className='datePicker'>
                                </div>
                            </div>
                        </div>
                        <div className='boardContent'>
                            <div className='boardThead'>
                                <div className='boardCol w-adj2'>
                                    S.NO.
                                </div>
                                <div className='boardCol w-adj2'>
                                    Employee Id
                                </div>
                                <div className='boardCol w-adj2'>
                                    Name
                                </div>
                                <div className='boardCol w-adj2'>
                                    Subject
                                </div>
                                <div className='boardCol w-adj2'>
                                    Message
                                </div>
                                {/* <div className='boardCol w-165'>
                                Create Date
                            </div>
                            <div className='boardCol w-110'>
                            </div> */}
                            </div>
                            {
                                fetchLoading ?
                                    <div className='d-flex justify-content-center my-4'>
                                        <div class=" text-center items-center spinner-border text-primary" style={{ width: '4rem', height: '4rem' }} role="status">
                                        </div>
                                    </div>
                                    :
                                    <>
                                        {filteredFeedbacks.map((item, index) => (
                                            <div className={`boardTrow ${(currentPage === 1 ? currentPage : ((currentPage - 1) * 10) + 1) <= index + 1 && index < (currentPage === 1 ? 10 : (currentPage * 10)) ? '' : 'd-none'} `} onClick={() => show(item)}>
                                                <div className='boardCol w-adj2'>
                                                    {index + 1}
                                                </div>
                                                <div className='boardCol w-adj2'>
                                                {`GB-000000${item.id}`}
                                                </div>
                                                <div className='boardCol w-adj2'>
                                                    <div className='boardTitle widAvatar'>
                                                        <img className='avatar' src={item.image || userImagePlaceholder} alt="" />
                                                        <strong>{item.full_name}</strong>
                                                        <span></span>
                                                    </div>
                                                </div>
                                                <div className='boardCol w-adj2'>
                                                    <a href="mailto:deanna.curtis@example.com">{item.subject}</a>
                                                </div>
                                                <div className='boardCol w-adj2'>
                                                    <a href="tel:(702) 555-0122">{item.message.length < 50 ? item.message : item.message.substring(0, 47) + "..."}</a>
                                                </div>
                                            </div>
                                        ))}
                                        {/* {data.length > 0 ?
                                            <>
                                                {data.map((item, index) => (
                                                    <div className={`boardTrow ${(currentPage === 1 ? currentPage : ((currentPage - 1) * 10) + 1) <= index + 1 && index < (currentPage === 1 ? 10 : (currentPage * 10)) ? '' : 'd-none'} `} onClick={() => show(item)}>
                                                        <div className='boardCol w-85'>
                                                            {index + 1}
                                                        </div>
                                                        <div className='boardCol w-165'>
                                                            GB-4936209
                                                        </div>
                                                        <div className='boardCol w-180'>
                                                            <div className='boardTitle widAvatar'>
                                                                <img className='avatar' src={Avatar01} alt="" />
                                                                <strong>{item.full_name}</strong>
                                                                <span>{item.company_name}</span>
                                                            </div>
                                                        </div>
                                                        <div className='boardCol w-320'>
                                                            <a href="mailto:deanna.curtis@example.com">{item.subject}</a>
                                                        </div>
                                                        <div className='boardCol w-50'>
                                                            <a href="tel:(702) 555-0122">{item.message.length < 50 ? item.message : item.message.substring(0, 47) + "..."}</a>
                                                        </div>
                                                    </div>
                                                ))}
                                            </> : <h2 className='text-danger text-center my-4 py-4'>Data not Found</h2>
                                        } */}
                                    </>
                            }


                        </div>
                        {/* <div className='boardFooter'>
                        <div className='pagination'>
                            <Link className='disabled' to={'/'}><img src={iconCaretLeft} alt='' /></Link>
                            <Link to={'/'}>1</Link>
                            <Link to={'/'}>4</Link>
                            <Link to={'/'}>5</Link>
                            <Link className='active' to={'/'}>6</Link>
                            <Link to={'/'}>7</Link>
                            <Link to={'/'}>8</Link>
                            <Link to={'/'}>50</Link>
                            <Link to={'/'}><img src={iconCaretRight} alt='' /></Link>
                            <Link className='wAuto' to={'/'}>10 / page <img className='ml-6' src={iconCaretDown} alt='' /></Link>
                            <span>Goto</span>
                            <input type='text' />
                        </div>
                    </div> */}
                        <Pagination
                            currentPage={currentPage}
                            setCurrentPage={setCurrentPage}
                            totalPages={getTotalPages}
                        />

                    </div>
                </div >
            </div >
            <Modal show={showModal} handleClose={close} colWidth={6}>
                <div className={[classes['modal-content-row-1'], "d-flex", "align-items-center"]}>
                    <div className={[classes['modal-content-row-1'], "d-flex", "align-items-center", "justify-content-between"].join(" ")}>
                        <div className='d-flex align-items-center'>
                            <div className={classes['profile-pic-wrapper']}>
                                <img alt='Avatar' src={Avatar01} />
                            </div>
                            <div>
                                <p className={classes['user-name']}>{modalContent.full_name}</p>
                                <p className={classes['user-company']}>{modalContent.company_name}</p>
                            </div>
                        </div>
                    </div>
                    <div className={[classes['modal-content-row-2'], "my-3"].join(" ")}>
                        <p className={[classes['subject-content'], "mb-0"].join(" ")}><strong>Subject:</strong>{modalContent.subject}</p>
                    </div>
                    <div className={[classes['modal-content-row-3'], "my-3"].join(" ")}>
                        <p className={[classes['message-content'], "mb-0"].join(" ")}>{modalContent.message}</p>
                    </div>
                </div>
            </Modal>
        </>
    )
}
export default AppFeedback