import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { showError, showSuccess } from "../../helpers/toast";

const API = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_URL,
});

API.interceptors.response.use(
  (response) => Promise.resolve(response),
  (error) => {
    // Display error message
    console.error(error.response?.data?.message || error.message);
    return Promise.reject(error);
  }
);

// Async thunk for creating a uploadpdf
export const createuploadpdf = createAsyncThunk(
  "uploadpdf/createuploadpdf",
  async (formData, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          Authorization: `${formData.get("accessToken")}`,
          "Content-Type": "multipart/form-data",
        },
      };
      const response = await API.post("/uploadpdf/", formData, config);
      showSuccess("Record created successfully");
      return response.data;
    } catch (error) {
      showError(error.response.data.error);
      return rejectWithValue(
        error.response?.data?.message || "Failed to create uploadpdf"
      );
    }
  }
);

// Async thunk for updating a uploadpdf
export const updateuploadpdf = createAsyncThunk(
  "uploadpdf/updateuploadpdf",
  async (
    { uploadpdfId, message, read_status, accessToken },
    { rejectWithValue }
  ) => {
    try {
      const config = {
        headers: {
          Authorization: `${accessToken}`,
          "Content-Type": "application/json",
        },
      };
      const response = await API.put(
        `/uploadpdf/${uploadpdfId}`,
        { message, read_status },
        config
      );
      showSuccess("uploadpdf updated successfully");
      return response.data;
    } catch (error) {
      return rejectWithValue(
        error.response?.data?.message || "Failed to update uploadpdf"
      );
    }
  }
);

// Async thunk for deleting a uploadpdf
export const deleteuploadpdf = createAsyncThunk(
  "uploadpdf/deleteuploadpdf",
  async ({ configdata }, { rejectWithValue }) => {
    console.log(configdata.uploadpdf_ids, "nnnnnnnnnnnnnnnnn");
    try {
      const config = {
        headers: {
          Authorization: `${configdata.accessToken}`,
          "Content-Type": "application/json",
        },
      };
      const response = await API.delete(
        `/uploadpdf/${configdata.uploadpdf_ids}`,
        config
      );
      //   showSuccess('uploadpdf deleted successfully');
      return configdata.uploadpdf_ids;
    } catch (error) {
      return rejectWithValue(
        error.response?.data?.message || "Failed to delete uploadpdf"
      );
    }
  }
);

// Async thunk for fetching uploadpdf
export const fetchuploadpdf = createAsyncThunk(
  "uploadpdf/fetchuploadpdf",
  async (accessToken, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          Authorization: `${accessToken}`,
          "Content-Type": "application/json",
        },
      };
      const response = await API.get("/uploadpdf/", config);
      return response.data;
    } catch (error) {
      return rejectWithValue(
        error.response?.data?.message || "Failed to fetch uploadpdf"
      );
    }
  }
);

const initialState = {
  uploadpdf: [],
  loading: false,
  error: null,
};

const uploadpdflice = createSlice({
  name: "uploadpdf",
  initialState,
  reducers: {},
  extraReducers: {
    [createuploadpdf.pending]: (state) => {
      state.loading = true;
    },
    [createuploadpdf.fulfilled]: (state, action) => {
      state.loading = false;
      state.uploadpdf.push(action.payload);
      state.error = null;
    },
    [createuploadpdf.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    [updateuploadpdf.fulfilled]: (state, action) => {
      state.loading = false;
      const index = state.uploadpdf.findIndex(
        (uploadpdf) => uploadpdf.id === action.payload.id
      );
      if (index !== -1) {
        state.uploadpdf[index] = action.payload;
      }
      state.error = null;
    },
    [deleteuploadpdf.fulfilled]: (state, action) => {
      state.loading = false;
      state.uploadpdf = state.uploadpdf.filter(
        (uploadpdf) => uploadpdf.id !== action.payload
      );
      state.error = null;
    },
    [fetchuploadpdf.pending]: (state) => {
      state.loading = true;
    },
    [fetchuploadpdf.fulfilled]: (state, action) => {
      state.loading = false;
      state.uploadpdf = action.payload;
      state.error = null;
    },
    [fetchuploadpdf.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export default uploadpdflice.reducer;
