import React, { useEffect, useState } from 'react';
import iconFilePdf from "../../../assets/media/icon-file-pdf.svg";
import iconFileXls from "../../../assets/media/icon-file-xls.svg";
import iconFileImage from "../../../assets/media/icon-file-image.svg";
import iconFileDoc from "../../../assets/media/icon-file-doc.svg";
import iconFileDownload from "../../../assets/media/icon-file-download.svg";
import iconFilecertification from "../../../assets/media/icon-certification.svg";
import iconSearch from "../../../assets/media/icon-search.svg"

import { Link, useNavigate } from 'react-router-dom';
import Navbar from '../../navbar';
import Header from '../../header';
import { useDispatch, useSelector } from 'react-redux';
import { AiOutlineEye } from 'react-icons/ai'
import { formatDate } from '../dashboard/aapDashboard';
import { fetchApplications, updateApplication } from '../../../store/reducers/applicationSlice';
import Pagination from '../dashboard/appPagination';
import { createNotification } from '../../../store/reducers/notificationSlice';
import { postCertificates } from '../../../store/reducers/certificateSlice';
import Modal from 'react-modal';

const ProcessApp = () => {
    const dispatch = useDispatch();
    const user = useSelector((state) => state.auth.user);
    const authToken = user.access_token;
    const navigate= useNavigate()

    const applications = useSelector((state) => state.applications);

    const [searchQuery, setSearchQuery] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const getallUserdata = useSelector((state) => state.userdata.getalluserdata);
    const [filteredApplication, setFilteredApplication] = useState([]);
    const applicationdata = useSelector((state) => state.applications.currentApp);
    const [modalIsOpen, setIsOpen] = React.useState(false);
    const [selectedDuaration, setselectedDuaration] = useState('')
    const categories = useSelector((state) => state.categories.data);

    useEffect(() => {
        dispatch(fetchApplications({ accessToken: authToken }));
    }, [authToken, dispatch]);

   useEffect(() => {
    if (applications.data.length > 0 && getallUserdata.length > 0) {
        const mergedData = applications.data.map((appItem) => {
            const userItem = getallUserdata.find((userData) => userData.id === appItem.user_id);
            if (userItem) {
                return {
                    ...appItem,
                    applicationId: appItem.id, 
                    ...userItem,
                    userId: userItem.id, 
                };
            }
            return { ...appItem, applicationId: appItem.id }; 
        });

        const filteredData = mergedData.filter((item) =>
        item.company_id === user.user.company_id &&
        item.state === "in_process" &&
        Object.values(item).some((value) =>
            value && value.toString().toLowerCase().includes(searchQuery.toLowerCase())
        )
    );

        setFilteredApplication(filteredData);
        console.log(filteredData, 'Filtered Data');
    }
}, [searchQuery, applications.data, getallUserdata, user.user.company_id]);

    const handleSearchInputChange = (e) => {
        setSearchQuery(e.target.value);
    };

    const getTotalPages = () => {
        if (Number.isInteger(filteredApplication.length / 10)) return filteredApplication.length / 10;
        return Math.floor(filteredApplication.length / 10) + 1;
    };

    const handlecertification = (e)=>{
        console.log(e,'llllllllllllllllllllllllllllllllllllll')
        // if (e === 'approved') {
        //     setIsOpen(true);
        // }
    }

    const closebutton = () => {
        setIsOpen(false)
    }
    const confirmcertification =  (e) => {
       
        const filteredCategories =  categories.filter((item) => item.name === applicationdata.form);

        const currentDate = new Date();
        const expirationDate = new Date(currentDate);
        
        if (selectedDuaration === '6month') {
            expirationDate.setMonth(expirationDate.getMonth() + 6);
        } else if (selectedDuaration === '1year') {
            expirationDate.setFullYear(expirationDate.getFullYear() + 1);
        }

        const postdata = {
            name: applicationdata.name,
            issued_at: currentDate.toISOString().split('T')[0],
            category_id: filteredCategories[0].id,
            issued_to: applicationdata.user_id,
            issued_by: user.user.id,
            expired_at: expirationDate.toISOString().split('T')[0],
        }
        const data = {
            accessToken:authToken,
            postdata:postdata
        }
        dispatch(postCertificates(data))
        dispatch(createNotification({
            message:`Create Certification for ${selectedDuaration === '6month' ? '6 Month':'1 Year '} `, 
            user_id:user.user.id, 
            accessToken :authToken,
            is_admin:false
        }))
        closebutton()
    }

    const handleDownload = (item) => {
        try {
            const parsedItem = JSON.parse(item);
            console.log(parsedItem[0]?.sections[0]?.column[0]?.row[1]?.content[0]?.url,'hggggsgggggg')
            const fileUrl = parsedItem[0]?.sections[0]?.column[0]?.row[1]?.content[0]?.url;
            if (fileUrl) {
                const xhr = new XMLHttpRequest();
                xhr.open('GET', fileUrl, true);
                xhr.responseType = 'blob';
                
                xhr.onload = function () {
                    if (xhr.status === 200) {
                        const blob = xhr.response;
                        const url = window.URL.createObjectURL(blob);
                        const link = document.createElement('a');
                        link.href = url;
                        link.download = 'downloaded_image.jpg';
                        document.body.appendChild(link);
                        link.click();
                        document.body.removeChild(link);
                        window.URL.revokeObjectURL(url);
                    } else {
                        console.error('Failed to download the file.');
                    }
                };
    
                xhr.onerror = function () {
                    console.error('Failed to download the file.');
                };
                
                xhr.send();
            } else {
                console.error('File URL not found in the JSON data.');
            }
        } catch (error) {
            console.error('Error parsing item string:', error);
        }
    };
    
    return (
        <div>
            <Navbar />
            <Header />
            <div className='wrapper'>
                <div className='boxAppBoard boxWhite'>
                    <div className='boardHeader'>
                        <div className='boardHeadLeft'>
                            <h2>In Process Applications</h2>
                        </div>
                        <div className='boardHeadRight'>
                            <div className='searchForm'>
                                <input type='text' placeholder='input search text' onChange={handleSearchInputChange} value={searchQuery} />
                                <button><img src={iconSearch} alt='' /></button>
                            </div>
                            <div className='datePicker'>
                            </div>
                        </div>
                    </div>
                    <div className='boardContent'>
                        <div className='boardThead'>
                            <div className='boardCol w-85'>
                                S.NO.
                            </div>
                            <div className='boardCol w-165'>
                                Application No.
                            </div>
                            <div className='boardCol w-adj4'>
                                Application Name
                            </div>
                            <div className='boardCol w-165'>
                                Submitted Date
                            </div>
                            <div className='boardCol w-250'>
                                {/* Attachments */}
                            </div>
                            <div className='boardCol w-110'>
                                Download
                            </div>
                            <div className='boardCol w-85'>
                                Actions
                            </div>
                            {/* <div className='boardCol w-85'>
                                Certification
                            </div> */}
                        </div>
                        {filteredApplication.sort((a, b) => b.applicationId - a.applicationId).map((item, index) => (
                            <div className={`boardTrow ${(currentPage === 1 ? currentPage : ((currentPage - 1) * 10) + 1) <= index + 1 && index < (currentPage === 1 ? 10 : (currentPage * 10)) ? '' : 'd-none'} `} key={item.id} >
                                <div className='boardCol w-85'>
                                    {index + 1}
                                </div>
                                <div className='boardCol w-165'>
                                {`GB-000000${item.applicationId}`}
                                </div>
                                <div className='boardCol w-adj4'>
                                    <div className='boardTitle'>
                                        <strong>{item.name}</strong>
                                        <span>{item.form}</span>
                                    </div>
                                </div>
                                <div className='boardCol w-165'>
                                    {item?.updated_at ? item.updated_at.slice(0, 10) : ''}
                                </div>
                                <div className='boardCol w-400'>
                                  
                                </div>
                                <div className='boardCol w-110'>
                                    <div className='boardActions' onClick={() => handleDownload(item.json_data)}>
                                        <img src={iconFileDownload} alt='' />
                                    </div>
                                </div>
                                <div className='boardCol w-85'>
                                    <AiOutlineEye onClick={() => navigate(`/view-applications/${item.applicationId}`)} style={{ cursor: 'pointer' }} />
                                </div>
                                {/* <div className='boardCol w-85'>
                                <div className='boardActions' onClick={()=>{handlecertification(item)}}>
                                       <img src={iconFilecertification} alt='' />
                                    </div>
                                </div> */}
                            </div>
                        ))}
                    </div>


                    <Modal
                                isOpen={modalIsOpen}
                                onRequestClose={closebutton}
                                contentLabel="Example Modal"
                                 >
                                    <div style={{ position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', backgroundColor: 'rgba(0, 0, 0, 0.5)', zIndex: 999 }}>
                                        <div style={{ backgroundColor: '#fff', width: '50%', margin: 'auto', marginTop: '10%', padding: '20px', borderRadius: '5px' }}>
                                            <h1 style={{ marginBottom: '20px' }}>Do you want certification?</h1>
                                            <div className="boardHeadRight">
                                                <div>
                                                    <select
                                                        className="form-select"
                                                        style={{ width: '100%' }}
                                                        value={selectedDuaration}
                                                        onChange={(e) => { setselectedDuaration(e.target.value) }}
                                                    >
                                                        <option selected  value="All">Select Duaration</option>
                                                        <option key='1'  value="6month">6 Month</option>
                                                        <option key='2' value="1year">1 Year</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div style={{ textAlign: 'right', marginTop: '20px' }}>
                                                <button style={{ marginRight: '10px', padding: '5px 10px', backgroundColor: '#ccc', border: 'none', borderRadius: '3px', cursor: 'pointer' }} onClick={(e) => { closebutton(e) }}>Close</button>
                                                <button onClick={(e) => { confirmcertification(e) }} style={{ padding: '5px 10px', backgroundColor: '#007bff', color: '#fff', border: 'none', borderRadius: '3px', cursor: 'pointer' }}>Confirm</button>
                                            </div>
                                        </div>
                                    </div>
                                </Modal>



                    <Pagination
                        currentPage={currentPage}
                        setCurrentPage={setCurrentPage}
                        totalPages={getTotalPages}
                    />
                </div>
            </div>
        </div>
    )
}

export default ProcessApp;
