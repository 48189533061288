import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useNavigate, useParams } from 'react-router-dom';

import Navbar from '../../navbar';
import Header from '../../header';

import { updateCategories, getCategoriesById } from '../../../store/reducers/categoriesSlice';

const UpdateCategories = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const accessToken = useSelector((state) => state.auth.user.access_token);
  const { id } = useParams(); // Assuming you have id in the route params

  // Define validation schema using Yup
  const validationSchema = Yup.object().shape({
    categoryName: Yup.string().required('Category Name is required'),
  });

  // Initialize Formik
  const formik = useFormik({
    initialValues: {
      categoryName: '',
    },
    validationSchema,
    onSubmit: async (values) => {
      // Dispatch the updateCategories action
      dispatch(updateCategories({ accessToken, id: id, name: values.categoryName }));

      // Navigate to another page after category update
      navigate('/categories'); // Replace with the desired navigation
    },
  });

  useEffect(() => {
    // Fetch category details when the component mounts
    dispatch(getCategoriesById({ accessToken, id: id }))
      .then((category) => {
        // Set the category name in the formik values
        formik.setValues({ categoryName: category?.payload?.name });
      })
      .catch((error) => {
        // Handle error or redirect to an error page
        console.error('Error fetching category details:', error);
        navigate('/error'); // Replace with the desired error page
      });
  }, []);

  return (
    <div>
      <Navbar />
      <Header />

      <div className="wrapper">
        <div className="card">
          <div className="card-header">
            <h3 className="mb-0">Update Category</h3>
          </div>
          <div className="card-body">
            <form onSubmit={formik.handleSubmit}>
              <div className="my-3">
                <label htmlFor="categoryName" className="form-label">
                  Category Name:
                </label>
                <input
                  type="text"
                  id="categoryName"
                  name="categoryName"
                  className={`form-control ${
                    formik.touched.categoryName && formik.errors.categoryName ? 'is-invalid' : ''
                  }`}
                  value={formik.values.categoryName}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                {formik.touched.categoryName && formik.errors.categoryName && (
                  <div className="invalid-feedback">{formik.errors.categoryName}</div>
                )}
              </div>

              <div className="text-end">
                <button type="submit" className="btn btn-primary">
                  Update
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UpdateCategories;
