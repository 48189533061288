import React, { useEffect } from "react";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import "./App.css";
import Login from "./components/pages/login";
import Dashboard from "./components/pages/dashboard";
import Employees from "./components/pages/employees";
import Profile from "./components/pages/profile";

import { store, persistor } from "./store";
import { Provider, useDispatch, useSelector } from "react-redux";

import Dashboard2 from "./components/pages/dashboard/Dashboard2";
import ProcessApp from "./components/pages/applications/in-process";
// import PendingApp from './components/pages/applications/pending';
import AppPending from "./components/pages/applications/appPending";
// import RejectedApp from './components/pages/applications/rejected';
import AppRejected from "./components/pages/applications/appRejected";
// import ApprovedApp from './components/pages/applications/approved';
import AppApproved from "./components/pages/applications/appApproved";
// import AppApproved from './components/pages/applications/approved';
import AppDiscrepancies from "./components/pages/applications/appDiscrepancies";
import AppRenewals from "./components/pages/applications/appRenewals";
import Feedback from "./components/pages/feedback";
import AppDraft from "./components/pages/applications/appDraft";
import AppCertifications from "./components/pages/appCertifications";
import FileCabinet from "./components/pages/file-cabinet";
import GamingLicence from "./components/pages/file-cabinet/gaming-licence";
import AppDashboard from "./components/pages/dashboard/aapDashboard";
import AppFormsAdmin from "./components/pages/appforms/appformAdmin";
// import AppFormCreation from './components/pages/formcreation';
import AppFormCreation from "./components/pages/formcreation/newIndex";
import Users from "./components/pages/users";
import { PersistGate } from "redux-persist/integration/react";
import AppProcessApp from "./components/pages/applications/app-in-process";
import AppViewApp from "./components/pages/applications/appViewApplication";
import AppEmployees from "./components/pages/appEmployees";
import AppUsers from "./components/pages/appUsers";
import AppFeedback from "./components/pages/appFeedback";
import Categories from "./components/pages/categories";
import CreateCategories from "./components/pages/categories/create";
import EditCategories from "./components/pages/categories/edit";
import Companies from "./components/pages/companies";
import Notifications from "./components/pages/notifications";
import { fetchNotifications } from "./store/reducers/notificationSlice";
import EmployeeDetails from "./components/pages/employeeDetails";

const MyRouter = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);
  useEffect(() => {
    if (user) {
      dispatch(fetchNotifications(user.access_token));
    }
  }, []);
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Login />} />
        {/* Dynamic Routes */}
        <Route
          path="/create-forms"
          element={user && user.user.is_admin ? <AppFormCreation /> : <Login />}
        />
        <Route
          path="/certifications"
          element={
            user && user.user.is_admin ? <AppCertifications /> : <Login />
          }
        />
        <Route
          path="/renewals"
          element={user && user.user.is_admin ? <AppRenewals /> : <Login />}
        />
        <Route
          path="/discrepancies"
          element={
            user && user.user.is_admin ? <AppDiscrepancies /> : <Login />
          }
        />
        <Route
          path="/draft"
          element={user && user.user.is_admin ? <AppDraft /> : <Login />}
        />

        <Route
          path="/profile"
          element={user && user.user.is_admin ? <Profile /> : <Login />}
        />
        {/* <Route path="/dashboard" element={user && user.user.is_admin? <Dashboard /> : <Login />} /> */}
        {/* <Route path="/forms" element={user && user.user.is_admin? <AppFormsAdmin /> : <Login />} /> */}
        <Route
          path="/forms/*"
          element={user && user.user.is_admin ? <AppFormsAdmin /> : <Login />}
        />
        <Route
          path="/dashboard"
          element={user && user.user.is_admin ? <AppDashboard /> : <Login />}
        />
        <Route
          path="/employees"
          element={user && user.user.is_admin ? <AppEmployees /> : <Login />}
        />
        <Route
          path="/employees/:id"
          element={user && user.user.is_admin ? <EmployeeDetails /> : <Login />}
        />
        {/* <Route path="/employees" element={user && user.user.is_admin? <Employees /> : <Login />} /> */}
        <Route
          path="/users"
          element={user && user.user.is_admin ? <AppUsers /> : <Login />}
        />
        {/* <Route path="/users" element={user && user.user.is_admin? <Users /> : <Login />} /> */}
        <Route
          path="/dashboard-2"
          element={user && user.user.is_admin ? <Dashboard2 /> : <Login />}
        />
        <Route
          path="/feedback"
          element={user && user.user.is_admin ? <AppFeedback /> : <Login />}
        />
        {/* <Route path="/feedback" element={user && user.user.is_admin? <Feedback /> : <Login />} /> */}
        <Route
          path="/in-process-applications"
          element={user && user.user.is_admin ? <AppProcessApp /> : <Login />}
        />
        <Route
          path="/view-applications/:id"
          element={user && user.user.is_admin ? <AppViewApp /> : <Login />}
        />
        {/* <Route path="/in-process-applications" element={user && user.user.is_admin? <ProcessApp /> : <Login />} /> */}
        <Route
          path="/pending-applications"
          element={user && user.user.is_admin ? <AppPending /> : <Login />}
        />
        <Route
          path="/rejected-applications"
          element={user && user.user.is_admin ? <AppRejected /> : <Login />}
        />
        <Route
          path="/approved-applications"
          element={user && user.user.is_admin ? <AppApproved /> : <Login />}
        />
        {/* <Route path="/discrepancies" element={user && user.user.is_admin? <Discrepancies /> : <Login />} /> */}
        {/* <Route path="/draft" element={user && user.user.is_admin? <Draft /> : <Login />} /> */}
        {/* <Route path="/renewals" element={user && user.user.is_admin? <Renewals /> : <Login />} /> */}
        {/* <Route path="/certifications" element={user && user.user.is_admin? <Certifications /> : <Login />} /> */}
        <Route
          path="/file-cabinet"
          element={user && user.user.is_admin ? <FileCabinet /> : <Login />}
        />
        <Route
          path="/gaming-licence"
          element={user && user.user.is_admin ? <GamingLicence /> : <Login />}
        />
        <Route
          path="/categories"
          element={user && user.user.is_admin ? <Categories /> : <Login />}
        />
        <Route
          path="/categories/create"
          element={
            user && user.user.is_admin ? <CreateCategories /> : <Login />
          }
        />
        <Route
          path="/categories/:id/edit"
          element={user && user.user.is_admin ? <EditCategories /> : <Login />}
        />
        <Route
          path="/compamies"
          element={user && user.user.is_admin ? <Companies /> : <Login />}
        />
        <Route
          path="/notification"
          element={user && user.user.is_admin ? <Notifications /> : <Login />}
        />
      </Routes>
    </Router>
  );
};

function App() {
  return (
    <React.StrictMode>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <MyRouter />
          <ToastContainer />
        </PersistGate>
      </Provider>
    </React.StrictMode>
  );
}

export default App;
