// reducers/index.js
import { combineReducers } from '@reduxjs/toolkit';
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import counterReducer from './counterSlice';
import authReducer from './authSlice';
import employeeReducer from './employeeSlice';
import signUpReducer from './signUpSlice';
import forgetPasswordReducer from './forgetPasswordSlice';
import resetPasswordReducer from './resetPasswordSlice';
import applicationReducer from './applicationSlice';
import formsReducer from './formSlice';
import categoriesReducer from './categoriesSlice';
import feedbackReducer from './feedbackSlice';
import certificateReducer from './certificateSlice';
import userdataReducer from './userSlice'
import companiesReducer from './companiesSlice';
import notificationReducer from './notificationSlice';
import userReducer from './userSlice';
import stateReducer from './stateSlice';
import uploadReducer from './uploadSlice';
import mandatoryFieldsReducer from './formMandatoryFieldsSlice';

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["auth", "counter"],
};

const rootReducer = combineReducers({
  counter: counterReducer,
  auth: authReducer,
  employee: employeeReducer,
  signUp: signUpReducer,
  forgetPassword: forgetPasswordReducer,
  resetPassword: resetPasswordReducer,
  applications: applicationReducer,
  categories: categoriesReducer,
  forms: formsReducer,
  feedbacks: feedbackReducer,
  certificates: certificateReducer,
  userdata: userdataReducer,
  companies: companiesReducer,
  notifications : notificationReducer,
  usersdata :userReducer,
  status :stateReducer,
  uploadpdf:uploadReducer,
  mandatoryFields: mandatoryFieldsReducer
});

// export default rootReducer;
export default persistReducer(persistConfig, rootReducer);
