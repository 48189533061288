// reducers/employeeSlice.js
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { showError, showSuccess } from "../../helpers/toast";
import axios from "axios";

const API = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_URL,
});
API.interceptors.response.use(
  (response) => {
    return Promise.resolve(response);
  },
  (error) => {
    showError(error.response?.data?.error ?? error.message);
    return Promise.reject(error);
  }
);

const initialState = {
  signupcode: null,
  password: null,
  full_name: null,
  phone: null,
  companyRegistrationNo: null,
  loading: false,
  message: null,
  error: null,
};

export const signUp = createAsyncThunk("signUp/", async (data) => {
  // console.log(data, 'data');
  try {
    const config = {
      headers: {
        "ngrok-skip-browser-warning": "69420",
        "Content-Type": "application/json",
      },
    };
    const response = await API.post("/users/signup", data.userData, config);
    showSuccess("Registered successfully!");
    return response.data;
  } catch (error) {
    throw new Error(error.response.data.error || "Failed to signup");
  }
});

const signUpSlice = createSlice({
  name: "signUp",
  initialState,
  reducers: {
    updateSignUpData: (state, action) => {
      state.signupcode = action.payload.signupcode;
      state.password = action.payload.password;
      state.full_name = action.payload.full_name;
      state.phone = action.payload.phone;
      state.companyRegistrationNo = action.payload.companyRegistrationNo;
    },
  },
  // reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(signUp.pending, (state) => {
        state.loading = true;
      })
      .addCase(signUp.fulfilled, (state, action) => {
        alert("User signed up successfully! Login to continue.");
        state.loading = false;
        state.message = action.payload;
        state.error = null;
      })
      .addCase(signUp.rejected, (state, action) => {
        alert(action.error.message);
        state.loading = false;
        state.error = action.error;
        // state.message = action.error;
      });
  },
});

export const { updateSignUpData } = signUpSlice.actions;
export default signUpSlice.reducer;
