// reducers/counterSlice.js
import { createSlice } from '@reduxjs/toolkit';

const counterSlice = createSlice({
  name: 'counter',
  initialState: {
    value: 0,
    formDropdown: true,
    applicationDropdown: true,
    activeItem: 'Dashboard',
    selectedControl: 'Controls',
    createFormData: {
      category: "",
      applicationName: '',
      id:null,
    },
    selectedPage: 1,
    pages: 1,
    sectionData: {
      column: 1,
      rows: 1,
      height: 60,
      margin: { left: 1, right: 2, top: 3, bottom: 4 }
    },
    fieldData: {
      margin: { left: 1, right: 2, top: 3, bottom: 4 },
      label: "Label",
      type: "text",
      field: "input",
      placeHolder: "Place Holder",
      checkboxes: [{
        id: 1,
        label: "Label 1",
        defaultChecked: true
      }],
      radios: [{
        id: 1,
        label: "Label 1",
        defaultChecked: true
      }],
      validations:{
        isRequired:false,
        validationType:'Email'
      }
    },
    uploadData: {
      margin: { left: 1, right: 2, top: 3, bottom: 4 },
      label: "Label",
      allowedFiles: [".jpg", " .jpeg", " .png", " .csv", ".doc", ".pdf", ".xls"]
    },
    imageData: {
      margin: { left: 1, right: 2, top: 3, bottom: 4 },
      height: 60,
      width: 60,
      display:'flex',
      justifycontent:'left'

    },
    textData: {
      margin: { left: 1, right: 2, top: 3, bottom: 4 },
      element: "h2",
      textAlign: "left",
      content: "Text",
      fontSize: 16,
    },
    buttonData: {
      margin: { left: 1, right: 2, top: 3, bottom: 4 },
      height: 60,
      label: "Button"

    },
    tableData: {
      margin: { left: 1, right: 2, top: 3, bottom: 4 },
      cols: [
        {
          id: 1,
          // title: "Col1",
          title: "",
        },
        {
          id: 2,
          // title: "Col2",
          title: "",
        },
        {
          id: 3,
          // title: "Col3",
          title: "",
        },
        {
          id: 4,
          // title: "Col4",
          title: "",
        },
        {
          id: 5,
          // title: "Col5",
          title: "",
        },
      ],
      noOfCols: 5,
      noOfRows: 5
    },
    selectedSection: {
      row: 0,
      column: 0,
    },
    updatePage: false
  },
  reducers: {
    toggleFormDropdown: (state) => {
      state.formDropdown = !state.formDropdown
    },
    toggleApplicationDropdown: (state) => {
      state.applicationDropdown = !state.applicationDropdown
    },
    increment: (state) => {
      state.value += 1;
    },
    decrement: (state) => {
      state.value -= 1;
    },
    setActiveItem: (state, action) => {
      state.activeItem = action.payload
    },
    setCreateForm: (state, action) => {
      state.createFormData = {
        category: action.payload.category,
        applicationName: action.payload.applicationName,
        formId: action.payload.id,
      }
    },
    setSelectedControl: (state, action) => {
      state.selectedControl = action.payload
    },
    setSectionData: (state, action) => {
      state.sectionData = action.payload
    },
    setFieldData: (state, action) => {
      state.fieldData = action.payload
    },
    addCheckBox: (state, action) => {
      state.fieldData = {
        ...state.fieldData,
        checkboxes: [
          ...state?.fieldData?.checkboxes,
          action.payload
        ]
      }
    },
    removeCheckbox: (state, action) => {
      const tempData = { ...state.fieldData }
      const checkboxIndex = tempData.checkboxes.findIndex(el => el.id === action.payload)
      tempData.checkboxes.splice(checkboxIndex, 1)

      state.fieldData = { ...tempData }
    },
    toggleCheckbox: (state, action) => {
      const tempData = { ...state.fieldData }
      const checkboxIndex = tempData.checkboxes.findIndex(el => el.id === action.payload)
      tempData.checkboxes[checkboxIndex].defaultChecked = !tempData.checkboxes[checkboxIndex].defaultChecked
      state.fieldData = { ...tempData }
    },
    addRadio: (state, action) => {
      state.fieldData = {
        ...state.fieldData,
        radios: [
          ...state?.fieldData?.radios,
          action.payload
        ]
      }
    },
    removeRadio: (state, action) => {
      const tempData = { ...state.fieldData }
      const radioIndex = tempData.radios.findIndex(el => el.id === action.payload)
      tempData.radios.splice(radioIndex, 1)

      state.fieldData = { ...tempData }
    },
    toggleRadio: (state, action) => {
      const tempData = { ...state.fieldData }
      const tempRadios = tempData.radios.map((item) => {
        if (item.id === action.payload) {
          return {
            ...item,
            defaultChecked: true
          }
        }
        else {
          return {
            ...item,
            defaultChecked: false
          }
        }
      })
      state.fieldData = { ...tempData, radios: [...tempRadios] }
    },
    changeRadioLabel: (state, action) => {
      const tempData = { ...state.fieldData }
      const radioIndex = tempData.radios.findIndex(el => el.id === action.payload.id)
      console.log({ tempData, radioIndex })
      tempData.radios[radioIndex].label = action.payload.label
      state.fieldData = { ...tempData }
    },
    changeCheckboxLabel: (state, action) => {
      const tempData = { ...state.fieldData }
      const checkboxIndex = tempData.checkboxes.findIndex(el => el.id === action.payload.id)
      console.log({ tempData, checkboxIndex })
      tempData.checkboxes[checkboxIndex].label = action.payload.label
      state.fieldData = { ...tempData }
    },
    setUploadData: (state, action) => {
      state.uploadData = action.payload
    },
    setImageData: (state, action) => {
      state.imageData = action.payload
    },
    setButtonData: (state, action) => {
      state.buttonData = action.payload
    },
    setTableData: (state, action) => {
      state.tableData = action.payload
    },
    setTextData: (state, action) => {
      state.textData = action.payload
    },
    setSelectedSection: (state, action) => {
      state.selectedSection = action.payload
    },
    setUpdatePage: (state, action) => {
      state.updatePage = action.payload;
    },
    incrementPage: (state) => {
      state.selectedPage += 1;
    },
    decrementPage: (state) => {
      state.selectedPage -= 1;
    },
    addPage: (state, action) => {
      state.pages += 1;
    },
  },
});

export const {
  increment,
  decrement,
  toggleApplicationDropdown,
  toggleFormDropdown,
  setActiveItem,
  setSelectedControl,
  setSectionData,
  setFieldData,
  addCheckBox,
  removeCheckbox,
  toggleCheckbox,
  addRadio,
  removeRadio,
  toggleRadio,
  changeCheckboxLabel,
  changeRadioLabel,
  setUploadData,
  setImageData,
  setButtonData,
  setTableData,
  setTextData,
  setSelectedSection,
  setUpdatePage,
  addPage,
  incrementPage,
  decrementPage,
  setCreateForm,

} = counterSlice.actions;
export default counterSlice.reducer;
